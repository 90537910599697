@font-face {
    font-family: "roboto";
    src: url("./Roboto/Roboto-Regular.woff");
}


body {
    padding: 0;
    margin: 0;
    font-family: roboto, sans-serif;
    font-size: 16px;
}

a {
    text-decoration: none !important;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: #02162e;
}

a:hover,
a:focus {
    color: #e24852;
    text-decoration: none;
}

button {
    outline: 0 !important;
}

p {
    margin-bottom: 15px;
    line-height: 1.8;
    color: #6b6b84;
    font-weight: 400;
    font-family: roboto, sans-serif;
    font-size: 16px;
}

p:last-child {
    margin-bottom: 0;
}

i {
    line-height: normal;
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    vertical-align: middle;
}

img {
    max-width: 100%;
    height: auto;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pt-70 {
    padding-top: 70px;
}

.pb-70 {
    padding-bottom: 70px;
}

.ptb-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #02162e;
    font-family: mulish, sans-serif;
    font-weight: 700;
}

.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
    margin-bottom: 0;
}

.default-btn {
    display: inline-block;
    background-color: #0b5ed7;
    color: #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 5px;
    padding: 12px 25px;
    font-weight: 400;
    font-size: 16px;
}

.default-btn:hover {
    background-color: #e24852;
    color: #ffffff;
}

.section-title {
    text-align: center;
    margin-bottom: 45px;
}

.section-title span {
    font-weight: 600;
    color: #e24852;
}

.section-title h2 {
    font-size: 38px;
    margin-top: 14px;
    margin-bottom: 16px;
}

.section-title p {
    margin-bottom: 0;
    max-width: 600px;
    margin: auto;
}

.top-header {
    background-color: #161c24;
    padding-top: 12px;
    padding-bottom: 12px;
}

.top-left {
    padding-left: 0;
    margin-bottom: 0;
}

.top-left li {
    color: #fff;
    display: inline-block;
    list-style-type: none;
    font-weight: 500;
}

.top-left li i {
    color: #e24852;
    font-size: 22px;
    margin-right: 5px;
}

.top-social {
    padding-left: 0;
    margin-bottom: 0;
    text-align: right;
}

.top-social li {
    list-style-type: none;
    display: inline-block;
    margin-right: 14px;
}

.top-social li:last-child {
    margin-right: 0;
}

.top-social li i {
    display: inline-block;
    height: 35px;
    width: 35px;
    line-height: 35px;
    background-color: transparent;
    text-align: center;
    border-radius: 30px;
    color: #fff;
    font-size: 22px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.top-social li i:hover {
    background-color: #5e0abd;
    text-underline-offset: 1px;
    text-decoration-line: underline;
    color: #fff;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
}

.middle-header-area {
    background-color: #e5e5e5;
    padding-top: 20px;
    padding-bottom: 20px;
}

.middle-logo h3 a {
    display: inline-block;
    font-size: 35px;
    color: #02162e;
}

.middle-info {
    position: relative;
    padding-left: 50px;
    display: inline-block;
    margin-right: 95px;
}

.middle-info::before {
    position: absolute;
    right: -52px;
    top: 2px;
    width: 1px;
    content: "";
    height: 46px;
    border: 1px dashed #e24852;
}

.middle-info:last-child {
    margin-right: 0;
}

.middle-info:last-child::before {
    display: none;
}

.middle-info .icon {
    position: absolute;
    left: 0;
    top: 0;
}

.middle-info .icon i {
    font-size: 35px;
    color: #e24852;
}

.middle-info h4 {
    margin-bottom: 5px;
    font-size: 18px;
    font-family: roboto, sans-serif;
}

.middle-info h4 a {
    color: #02162e;
}

.middle-info h4 a:hover {
    color: #e24852;
}

.middle-info span {
    font-size: 15px;
}

.middle-btn {
    text-align: right;
}

.middle-btn .default-btn {
    padding: 10px 20px;
}

.middle-btn .default-btn i {
    font-size: 20px;
    position: relative;
    top: 2px;
}

.main-responsive-nav {
    display: none;
}

.main-navbar {
    padding-top: 0;
}

.main-navbar .container-fluid {
    max-width: 100wh;
}

.main-navbar .navbar {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding-right: 0;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
}

.main-navbar .navbar ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}

.main-navbar .navbar .navbar-nav {
    margin: auto;
}

.main-navbar .navbar .navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
}

.main-navbar .navbar .navbar-nav .nav-item a {
    color: #6b6b84 !important;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-weight: 600;
    text-transform: capitalize;
    padding-left: 0;
    padding-right: 35px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.main-navbar .navbar .navbar-nav .nav-item a i {
    font-size: 16px;
    position: relative;
    top: 2px;
    display: inline-block;
}

.main-navbar .navbar .navbar-nav .nav-item a:hover,
.main-navbar .navbar .navbar-nav .nav-item a:focus,
.main-navbar .navbar .navbar-nav .nav-item a.active {
    color: #e24852 !important;
}

.main-navbar .navbar .navbar-nav .nav-item:hover a,
.main-navbar .navbar .navbar-nav .nav-item.active a {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu {
    border: none;
    top: 70px;
    left: 0;
    z-index: 99;
    opacity: 0;
    width: 250px;
    display: block;
    border-radius: 0;
    padding: 10px 0;
    margin-top: 15px;
    position: absolute;
    visibility: hidden;
    background: #fff;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li {
    margin: 0;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a {
    padding: 10px 20px;
    position: relative;
    display: block;
    color: #6b6b84;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a i {
    margin: 0;
    position: absolute;
    top: 50%;
    font-size: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
    color: #e24852;
    letter-spacing: 1px;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    top: 0;
    opacity: 0;
    right: -250px;
    left: auto;
    margin-top: 15px;
    visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
    color: #02162e;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
    top: 0;
    opacity: 0;
    left: 250px;
    visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
    color: #02162e;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    top: 0;
    opacity: 0;
    left: -250px;
    visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
    color: #02162e;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    top: 0;
    opacity: 0;
    left: 250px;
    visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
    color: #02162e;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    top: 0;
    opacity: 0;
    left: -250px;
    visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
    color: #02162e;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    top: 0;
    opacity: 0;
    left: 250px;
    visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
    color: #02162e;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
    color: #e24852;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
}

.main-navbar .navbar .navbar-nav .nav-item:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
}

.main-navbar .navbar .others-options .option-item {
    margin-right: 20px;
}

.main-navbar .navbar .others-options .option-item:last-child {
    margin-right: 0;
}

.main-navbar .navbar .others-options .option-item .cart-btn {
    position: relative;
}

.main-navbar .navbar .others-options .option-item .cart-btn i {
    display: inline-block;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    background-color: #efefef;
    color: #e24852;
    font-size: 22px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 30px;
    font-weight: 700;
    border: 2px solid #efefef;
}

.main-navbar .navbar .others-options .option-item .cart-btn i:hover {
    background-color: transparent;
    color: #e24852;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
}

.main-navbar .navbar .others-options .option-item .cart-btn span {
    position: absolute;
    right: -1px;
    top: 2px;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 50%;
    background: #e24852;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.main-navbar .navbar .others-options .option-item .search-btn {
    display: inline-block;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    background-color: #efefef;
    color: #e24852;
    font-size: 22px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 30px;
    font-weight: 700;
    border: 2px solid #efefef;
    cursor: pointer;
}

.main-navbar .navbar .others-options .option-item .search-btn:hover {
    background-color: transparent;
    color: #e24852;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
}

.main-navbar .navbar .others-options .option-item .close-btn {
    display: none;
    height: 45px;
    width: 45px;
    line-height: 42px;
    text-align: center;
    background-color: #efefef;
    color: #e24852;
    font-size: 18px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 30px;
    font-weight: 700;
    border: 2px solid #efefef;
    cursor: pointer;
}

.main-navbar .navbar .others-options .option-item .close-btn:hover {
    background-color: transparent;
    color: #e24852;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
}

.main-navbar .navbar .others-options .option-item .close-btn.active {
    display: block;
}

.main-navbar .navbar .others-options .option-item .burger-menu i {
    display: inline-block;
    height: 45px;
    width: 45px;
    line-height: 44px;
    text-align: center;
    background-color: #efefef;
    color: #e24852;
    font-size: 22px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 30px;
    font-weight: 700;
    border: 2px solid #efefef;
    cursor: pointer;
}

.main-navbar .navbar .others-options .option-item .burger-menu i:hover {
    background-color: transparent;
    color: #e24852;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
}

.main-navbar .navbar .others-options .option-item .default-btn {
    border: 1px solid #e24852;
}

.p-relative .main-navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.p-relative .main-navbar .navbar {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    padding-left: 40px;
    padding-right: 40px;
    position: absolute;
    width: 100%;
    z-index: 9;
    left: 0;
    right: 0;
    max-width: 100vw;
    margin: auto;
}

.p-relative .main-navbar .navbar .navbar-brand {
    display: none;
}

.p-relative .main-navbar .navbar .navbar-nav .nav-item a {
    color: #02162e;
}

.p-relative.is-sticky .main-navbar {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
}

.navbar-area.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    background-color: #fff !important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.search-overlay {
    display: none;
}

.search-overlay.search-popup {
    position: absolute;
    top: 100%;
    width: 300px;
    background: #fff;
    z-index: 2;
    right: 0;
    padding: 20px;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    margin-top: 0;
}

.search-overlay.search-popup .search-form {
    position: relative;
}

.search-overlay.search-popup .search-form .search-input {
    display: block;
    width: 100%;
    height: 50px;
    line-height: initial;
    border: 1px solid #eee;
    color: #02162e;
    outline: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding-top: 4px;
    padding-left: 10px;
}

.search-overlay.search-popup .search-form .search-input:focus {
    border-color: #e24852;
}

.search-overlay.search-popup .search-form .search-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    background: 0 0;
    border: none;
    width: 50px;
    outline: 0;
    color: #6b6b84;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding: 0;
}

.search-overlay.search-popup .search-form .search-button:hover,
.search-overlay.search-popup .search-form .search-button:focus {
    color: #e24852;
}

.search-overlay.search-popup .search-form .search-button i {
    font-size: 18px;
    font-weight: 700;
}

.sidebar-modal {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    overflow: hidden;
}

.sidebar-modal .sidebar-modal-inner {
    position: absolute;
    right: -100%;
    top: 0;
    width: 30%;
    overflow-y: scroll;
    height: 100%;
    background-color: #fff;
    -webkit-transition: 0.7s;
    transition: 0.7s;
    z-index: 1;
    padding-top: 80px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
}

.sidebar-modal .sidebar-modal-inner .close-btn {
    display: inline-block;
    position: absolute;
    right: 35px;
    top: 20px;
    font-size: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: #02162e;
    opacity: 0.66;
    cursor: pointer;
}

.sidebar-modal .sidebar-modal-inner .close-btn:hover {
    opacity: 1;
}

.sidebar-modal .sidebar-about-area {
    margin-bottom: 40px;
}

.sidebar-modal .sidebar-about-area .title h2 {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 700;
    font-family: roboto, sans-serif;
}

.sidebar-modal .sidebar-about-area .title p {
    margin-top: 15px;
    font-size: 15px;
}

.sidebar-modal .sidebar-instagram-feed {
    margin-bottom: 40px;
}

.sidebar-modal .sidebar-instagram-feed h2 {
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: 700;
    font-family: roboto, sans-serif;
}

.sidebar-modal .sidebar-instagram-feed ul {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    list-style-type: none;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 0;
    margin-top: -10px;
}

.sidebar-modal .sidebar-instagram-feed ul li {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
}

.sidebar-modal .sidebar-instagram-feed ul li a {
    display: block;
    position: relative;
}

.sidebar-modal .sidebar-instagram-feed ul li a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.sidebar-modal .sidebar-instagram-feed ul li a:hover::before {
    opacity: 0.5;
    visibility: visible;
}

.sidebar-modal .sidebar-contact-area .contact-info {
    text-align: center;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 {
    margin-bottom: 0;
    text-transform: lowercase;
    font-size: 20px;
    font-family: roboto, sans-serif;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a {
    display: inline-block;
    color: #e24852;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:hover {
    color: #02162e;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child) {
    color: #02162e;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child):hover {
    color: #e24852;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 span {
    display: block;
    color: #6b6b84;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 20px;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li {
    display: inline-block;
    margin: 0 1px;
    padding-left: 0;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a i {
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border: 1px solid #dadada;
    border-radius: 50%;
    color: #aba5a5;
    display: block;
    font-size: 25px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a i:hover {
    color: #fff;
    border-color: #e24852;
    background-color: #e24852;
}

.sidebar-modal.active {
    opacity: 1;
    visibility: visible;
}

.sidebar-modal.active .sidebar-modal-inner {
    right: 0;
}

.others-option-for-responsive {
    display: none;
}

.others-option-for-responsive .dot-menu {
    padding: 0 10px;
    height: 30px;
    cursor: pointer;
    z-index: 9991;
    position: absolute;
    right: 60px;
    top: -28px;
}

.others-option-for-responsive .dot-menu .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 30px;
}

.others-option-for-responsive .dot-menu .inner .circle {
    height: 5px;
    width: 5px;
    border-radius: 100%;
    margin: 0 2px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #02162e;
}

.others-option-for-responsive .dot-menu:hover .inner .circle {
    background-color: #e24852;
}

.others-option-for-responsive .container {
    position: relative;
}

.others-option-for-responsive .container .container {
    position: absolute;
    right: 0;
    top: 10px;
    max-width: 260px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    margin-left: auto;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    z-index: 2;
    padding-left: 15px;
    padding-right: 15px;
}

.others-option-for-responsive .container .container.active {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.others-option-for-responsive .option-inner .others-options .option-item {
    margin-right: 20px;
}

.others-option-for-responsive .option-inner .others-options .option-item:last-child {
    margin-right: 0;
}

.others-option-for-responsive .option-inner .others-options .option-item .cart-btn {
    position: relative;
}

.others-option-for-responsive .option-inner .others-options .option-item .cart-btn i {
    display: inline-block;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    background-color: #efefef;
    color: #e24852;
    font-size: 22px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 30px;
    font-weight: 700;
    border: 2px solid #efefef;
}

.others-option-for-responsive .option-inner .others-options .option-item .cart-btn i:hover {
    background-color: transparent;
    color: #e24852;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
}

.others-option-for-responsive .option-inner .others-options .option-item .cart-btn span {
    position: absolute;
    right: -1px;
    top: 2px;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 50%;
    background: #e24852;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.others-option-for-responsive .option-inner .others-options .option-item .search-btn {
    display: inline-block;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    background-color: #efefef;
    color: #e24852;
    font-size: 22px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 30px;
    font-weight: 700;
    border: 2px solid #efefef;
    cursor: pointer;
}

.others-option-for-responsive .option-inner .others-options .option-item .search-btn:hover {
    background-color: transparent;
    color: #e24852;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
}

.others-option-for-responsive .option-inner .others-options .option-item .close-btn {
    display: none;
    height: 45px;
    width: 45px;
    line-height: 42px;
    text-align: center;
    background-color: #efefef;
    color: #e24852;
    font-size: 18px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 30px;
    font-weight: 700;
    border: 2px solid #efefef;
    cursor: pointer;
}

.others-option-for-responsive .option-inner .others-options .option-item .close-btn:hover {
    background-color: transparent;
    color: #e24852;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
}

.others-option-for-responsive .option-inner .others-options .option-item .close-btn.active {
    display: block;
}

.others-option-for-responsive .option-inner .others-options .option-item .burger-menu i {
    display: inline-block;
    height: 45px;
    width: 45px;
    line-height: 44px;
    text-align: center;
    background-color: #efefef;
    color: #e24852;
    font-size: 22px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 30px;
    font-weight: 700;
    border: 2px solid #efefef;
    cursor: pointer;
}

.others-option-for-responsive .option-inner .others-options .option-item .burger-menu i:hover {
    background-color: transparent;
    color: #e24852;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
}

.others-option-for-responsive .option-inner .others-options .option-item .default-btn {
    border: 1px solid #e24852;
}

@media only screen and (max-width: 1199px) {
    .main-responsive-nav {
        display: block;
    }
    .main-responsive-nav .main-responsive-menu {
        position: relative;
    }
    .main-responsive-nav .main-responsive-menu.mean-container .mean-nav ul {
        font-size: 15px;
    }
    .main-responsive-nav .main-responsive-menu.mean-container .mean-nav ul li a.active {
        color: #e24852;
    }
    .main-responsive-nav .main-responsive-menu.mean-container .mean-nav ul li a i {
        display: none;
    }
    .main-responsive-nav .main-responsive-menu.mean-container .mean-nav ul li li a {
        font-size: 14px;
    }
    .main-responsive-nav .main-responsive-menu.mean-container .others-options {
        display: none !important;
    }
    .main-responsive-nav .main-responsive-menu.mean-container .navbar-nav {
        overflow-y: scroll;
        height: 255px;
        -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
        background-color: #fff;
    }
    .main-responsive-nav .mean-container a.meanmenu-reveal {
        color: #02162e;
    }
    .main-responsive-nav .mean-container a.meanmenu-reveal span {
        background: #02162e;
        position: relative;
    }
    .main-responsive-nav .logo {
        position: relative;
    }
    .main-responsive-nav .logo img {
        max-width: 100px !important;
    }
    .navbar-area {
        background-color: #fff;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .main-navbar {
        display: none;
    }
    .others-option-for-responsive {
        display: block;
    }
}

.main-slider-item {
    position: relative;
    z-index: 1;
    background-image: url("img/slides/slides-bg1.webp");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 850px;
}

.main-slider-item::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0.5;
    z-index: -1;
}

.main-slider-item.item-two {
    background-image: url("img/slides/slides-bg2.webp");
}

.main-slider-item.item-three {
    background-image: url("img/slides/slides-bg3.webp");
}

.main-slider-content {
    max-width: 650px;
    margin-top: 70px;
}

.main-slider-content strong {
    display: inline-block;
    padding: 5px 20px;
    background-color: #e24852;
    color: #fff;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 700;
    font-family: mulish, sans-serif;
}

.main-slider-content h1 {
    font-size: 55px;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 20px;
}

.main-slider-content p {
    margin-bottom: 0;
    color: #fff;
}

.main-slider-content .slider-btn {
    margin-top: 35px;
}

.main-slider-content .slider-btn .default-btn-two {
    display: inline-block;
    text-align: center;
    z-index: 1;
    background-color: #fff;
    color: #e24852;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 5px;
    padding: 12px 25px;
    margin-left: 20px;
    font-weight: 400;
    font-size: 16px;
}

.main-slider-content .slider-btn .default-btn-two:hover {
    background-color: #e24852;
    color: #fff;
}

.home-slides.owl-theme .owl-nav {
    margin-top: 0;
}

.home-slides.owl-theme .owl-nav [class*="owl-"] {
    position: absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0;
    outline: 0;
    width: 45px;
    height: 45px;
    line-height: 52px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #fff;
    color: #e24852;
    border-radius: 50%;
    opacity: 1;
    visibility: hidden;
}

.home-slides.owl-theme .owl-nav [class*="owl-"]:hover,
.home-slides.owl-theme .owl-nav [class*="owl-"]:focus {
    background-color: #02162e;
}

.home-slides.owl-theme .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: 10px;
}

.home-slides.owl-theme .owl-nav [class*="owl-"] i {
    font-size: 25px;
    font-weight: 700;
}

.home-slides.owl-theme:hover .owl-nav [class*="owl-"] {
    opacity: 1;
    visibility: visible;
    left: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.home-slides.owl-theme:hover .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: 20px;
}

.main-banner-item {
    position: relative;
    z-index: 1;
    background-image: url("img/main-banner-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 800px;
    background-attachment: fixed;
}

.main-banner-item::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0.7;
    z-index: -1;
}

.main-banner-item.item-two {
    background-image: url("img/main-banner-bg2.jpg");
}

.main-banner-item.item-two .main-banner-content {
    max-width: 750px;
}

.main-banner-content {
    max-width: 650px;
    margin: auto;
    text-align: center;
}

.main-banner-content.two {
    max-width: 100%;
    text-align: left;
}

.main-banner-content h1 {
    font-size: 55px;
    color: #fff;
    font-family: roboto, sans-serif;
    margin-top: -10px;
    margin-bottom: 20px;
}

.main-banner-content p {
    margin-bottom: 0;
    color: #fff;
}

.main-banner-content .banner-btn {
    margin-top: 35px;
}

.main-banner-content .banner-btn .default-btn-two {
    display: inline-block;
    text-align: center;
    z-index: 1;
    background-color: #fff;
    color: #e24852;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 5px;
    padding: 12px 25px;
    margin-left: 20px;
    font-weight: 400;
    font-size: 16px;
}

.main-banner-content .banner-btn .default-btn-two:hover {
    background-color: #e24852;
    color: #fff;
}

.banner-form {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    padding: 50px 40px;
    position: relative;
    border-radius: 10px;
}

.banner-form .content {
    margin-bottom: 35px;
}

.banner-form .content span {
    color: #e24852;
    font-weight: 500;
}

.banner-form .content h3 {
    font-size: 32px;
    margin-top: 14px;
    margin-bottom: 0;
}

.banner-form .form-group {
    position: relative;
    margin-bottom: 15px;
}

.banner-form .form-group .form-control {
    padding: 15px 20px;
    border: 1px dashed #f6f6f6;
    color: #5d5d5d;
    border-radius: 10px;
    background-color: #f6f6f6;
    font-size: 14px;
    font-weight: 400;
    height: 60px;
}

.banner-form .form-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.banner-form .form-group i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 20px;
    line-height: 1;
    font-size: 18px;
    color: #04b8e5;
}

.banner-form textarea {
    height: 130px !important;
}

.banner-form .nice-select {
    height: 60px;
    width: 100%;
    line-height: 48px;
    font-size: 15px;
    margin-bottom: 15px;
    padding-left: 20px;
    border: 1px dashed #f6f6f6;
    color: #5d5d5d;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding-top: 5px;
}

.banner-form .nice-select .list {
    background-color: #fff;
    -webkit-box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    border-radius: 0;
    margin-top: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.banner-form .nice-select .list .option {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    color: #02162e;
    padding-left: 20px;
    padding-right: 20px;
}

.banner-form .nice-select .list .option:hover {
    background-color: #e24852 !important;
    color: #fff;
}

.banner-form .nice-select .list .option.selected {
    background-color: transparent;
    font-weight: 600;
}

.banner-form .nice-select::after {
    height: 8px;
    width: 8px;
    border-color: #04b8e5;
    right: 23px;
}

.banner-form .banner-btn {
    margin-top: 15px;
}

.banner-form .banner-btn .default-btn {
    border: 1px solid #e24852;
    cursor: pointer;
}

.about-area {
    position: relative;
    z-index: 1;
}

.about-area::before {
    position: absolute;
    content: "";
    background-image: url("img/about-shape-bg.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 30%;
    z-index: -1;
}

.about-area.bg-ffffff {
    overflow: hidden;
}

.about-area.bg-ffffff::before {
    display: none;
}

.about-content span {
    color: #e24852;
    font-weight: 500;
}

.about-content h3 {
    font-size: 38px;
    margin-top: 14px;
    margin-bottom: 16px;
}

.about-content p {
    margin-bottom: 0;
}

.about-content .about-list {
    padding-left: 0;
    margin-top: 25px;
    margin-bottom: 0;
}

.about-content .about-list li {
    list-style-type: none;
    color: #02162e;
    font-weight: 500;
    position: relative;
    margin-bottom: 18px;
}

.about-content .about-list li:last-child {
    margin-bottom: 0;
}

.about-content .about-list li i {
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    background-color: #f2f4fd;
    color: #e24852;
    text-align: center;
    font-size: 22px;
    border-radius: 30px;
    margin-right: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.about-content .about-list li:hover i {
    background-color: #e24852;
    color: #fff;
}

.about-content .signature {
    position: relative;
    padding: 0 235px 0 0;
    margin-top: 38px;
    display: inline-block;
}

.about-content .signature::before {
    position: absolute;
    left: 42%;
    top: 6px;
    width: 2px;
    content: "";
    height: 45px;
    background-color: #e24852;
}

.about-content .signature img {
    position: absolute;
    right: 35px;
    top: 10px;
}

.about-content .signature h4 {
    font-size: 20px;
    margin-bottom: 5px;
}

.about-content .signature span {
    font-size: 15px;
    color: #909090;
    font-weight: 500;
}

.about-inner {
    margin-top: 25px;
}

.about-inner .icon {
    margin-bottom: 12px;
}

.about-inner .icon i {
    font-size: 50px;
    color: #e24852;
}

.about-inner h4 {
    font-size: 25px;
    margin-bottom: 14px;
}

.about-inner p {
    margin-bottom: 0;
}

.about-image {
    position: relative;
    margin-bottom: 30px;
}

.about-image::before {
    position: absolute;
    content: "";
    height: 90%;
    width: 95%;
    background-color: #e24852;
    right: -30px;
    bottom: -30px;
    z-index: -1;
}

.about-image .about-years {
    display: inline-block;
    height: 120px;
    width: 120px;
    background-color: #f8f8f8;
    text-align: center;
    position: absolute;
    left: -50px;
    top: 80%;
    -webkit-transform: translateY(-80%);
    transform: translateY(-80%);
    padding-top: 25px;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.about-image .about-years h5 {
    font-size: 30px;
    color: #e24852;
    margin-bottom: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.about-image .about-years span {
    font-size: 20px;
    font-weight: 700;
    color: #02162e;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.about-image .about-years:hover {
    background-color: #e24852;
}

.about-image .about-years:hover h5 {
    color: #fff;
}

.about-image .about-years:hover span {
    color: #fff;
}

.about-warp {
    position: absolute;
    top: 100px;
}

.about-information {
    background-color: #efefef;
    padding: 30px;
    margin-top: 35px;
    position: relative;
    overflow: hidden;
}

.about-information::before {
    position: absolute;
    bottom: -16px;
    right: -5px;
    width: 0;
    height: 0;
    content: "";
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 25px solid #e6757c;
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

.about-information h5 {
    font-size: 22px;
    margin-bottom: 20px;
}

.about-information h6 {
    font-size: 22px;
    margin-bottom: 0;
}

.about-information h6 a {
    color: #e24852;
}

.about-information h6 i {
    font-size: 35px;
    margin-right: 5px;
}

.about-warp-image {
    position: relative;
    padding-bottom: 50px;
    padding-left: 50px;
}

.about-warp-image img:nth-child(2) {
    position: absolute;
    left: 35px;
    bottom: -22px;
    z-index: 1;
    padding: 0;
    border-top: 10px solid #fff;
    border-right: 10px solid #fff;
}

.about-warp-image .about-years {
    display: inline-block;
    height: 120px;
    width: 120px;
    background-color: #f8f8f8;
    text-align: center;
    position: absolute;
    left: 5px;
    top: 85%;
    -webkit-transform: translateY(-85%);
    transform: translateY(-85%);
    padding-top: 25px;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 1;
}

.about-warp-image .about-years h5 {
    font-size: 30px;
    color: #e24852;
    margin-bottom: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.about-warp-image .about-years span {
    font-size: 20px;
    font-weight: 700;
    color: #02162e;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.about-warp-image .about-years:hover {
    background-color: #e24852;
}

.about-warp-image .about-years:hover h5 {
    color: #fff;
}

.about-warp-image .about-years:hover span {
    color: #fff;
}

.services-area {
    background-image: url("img/services-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: repeat;
}

.services-area .section-title span {
    color: #e24852;
}

.services-area .section-title h2 {
    color: #fff;
}

.services-area .section-title p {
    color: #fff;
}

.services-area.bg-ffffff {
    background-image: url("img/services-bg2.jpg");
}

.services-area.bg-ffffff .section-title h2 {
    color: #02162e;
}

.services-area.bg-ffffff .section-title p {
    color: #6b6b84;
}

.services-area.bg-f1f0f3 {
    background-image: unset;
    background-color: #f1f0f3;
}

.services-area.bg-f1f0f3 .section-title h2 {
    color: #02162e;
}

.services-area.bg-f1f0f3 .section-title p {
    color: #6b6b84;
}

.services-area.bg-666666 {
    background-image: unset;
    background-color: #fff;
}

.services-area.bg-666666 .section-title h2 {
    color: #02162e;
}

.services-area.bg-666666 .section-title p {
    color: #6b6b84;
}

.services-item .image a img {
    display: inline-block;
}

.services-item .content {
    background-color: #1b242d;
    padding: 30px;
}

.services-item .content h3 {
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 600;
}

.services-item .content h3 a {
    color: #fff;
}

.services-item .content h3 a:hover {
    color: #e24852;
}

.services-item .content p {
    color: #c4c4c5;
    font-size: 15px;
}

.services-slider.owl-theme .owl-nav {
    margin-top: 0;
}

.services-slider.owl-theme .owl-nav [class*="owl-"] {
    position: absolute;
    left: -10px;
    top: 40%;
    -webkit-transform: translateY(-40%);
    transform: translateY(-40%);
    margin: 0;
    outline: 0;
    width: 45px;
    height: 45px;
    line-height: 52px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #fff;
    color: #e24852;
    border-radius: 50%;
    opacity: 1;
    visibility: hidden;
}

.services-slider.owl-theme .owl-nav [class*="owl-"]:hover,
.services-slider.owl-theme .owl-nav [class*="owl-"]:focus {
    background-color: #02162e;
}

.services-slider.owl-theme .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: -10px;
}

.services-slider.owl-theme .owl-nav [class*="owl-"] i {
    font-size: 25px;
    font-weight: 700;
}

.services-slider.owl-theme:hover .owl-nav [class*="owl-"] {
    opacity: 1;
    visibility: visible;
    left: -20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.services-slider.owl-theme:hover .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: -20px;
}

.single-services {
    margin-bottom: 30px;
    text-align: center;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-services .image {
    position: relative;
}

.single-services .image a img {
    display: inline-block;
}

.single-services .image .icon {
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
    z-index: 9;
}

.single-services .image .icon i {
    display: inline-block;
    height: 60px;
    width: 60px;
    line-height: 60px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    color: #e24852;
    text-align: center;
    font-size: 30px;
    border-radius: 50px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-services .content {
    background-color: #fff;
    padding: 45px 30px 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: relative;
    z-index: 1;
    border-radius: 0 0 8px 8px;
}

.single-services .content h3 {
    font-size: 25px;
    margin-bottom: 14px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-services .content h3 a {
    color: #02162e;
}

.single-services .content p {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 15px;
}

.single-services .content::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 0%;
    background: #e24852;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 0 0 8px 8px;
}

.single-services:hover .content::before {
    height: 100%;
}

.single-services:hover .content h3 a {
    color: #fff;
}

.single-services:hover .content h3 a:hover {
    color: #e24852;
}

.single-services:hover .content p {
    color: #fff;
}

.single-services:hover .image .icon i {
    background-color: #02162e;
    color: #fff;
}

.single-services-item {
    margin-bottom: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    padding: 30px;
    margin-top: 20px;
    margin-right: 20px;
    position: relative;
    z-index: 1;
}

.single-services-item::before {
    content: "";
    position: absolute;
    right: -15px;
    top: -15px;
    z-index: -1;
    width: 100%;
    height: 100%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border: 5px solid #e24852;
}

.single-services-item::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #fff;
}

.single-services-item .icon {
    margin-bottom: 20px;
}

.single-services-item .icon i {
    display: inline-block;
    height: 65px;
    width: 65px;
    line-height: 65px;
    background-color: transparent;
    border: 1px dashed #e24852;
    color: #e24852;
    text-align: center;
    font-size: 30px;
    border-radius: 50px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-services-item h3 {
    font-size: 25px;
    margin-bottom: 14px;
}

.single-services-item h3 a {
    color: #02162e;
}

.single-services-item .learn-btn {
    color: #e24852;
    font-weight: 500;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-services-item .learn-btn i {
    font-size: 22px;
    position: relative;
    top: 3px;
}

.single-services-item:hover {
    margin-top: 0;
    margin-right: 0;
}

.single-services-item:hover::before {
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
}

.single-services-item:hover .icon i {
    background-color: #e24852;
    color: #fff;
}

.single-services-item:hover .learn-btn {
    letter-spacing: 1px;
}

.choose-item {
    position: relative;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding-left: 65px;
    margin-bottom: 35px;
}

.choose-item.two {
    margin-bottom: 0;
}

.choose-item .icon {
    position: absolute;
    left: 0;
    top: 0;
}

.choose-item .icon i {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    background-color: #e24852;
    color: #fff;
    font-size: 25px;
    text-align: center;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.choose-item h3 {
    font-size: 25px;
    margin-bottom: 10px;
}

.choose-item:hover .icon i {
    background-color: #fdced2;
    color: #02162e;
}

.choose-image {
    text-align: center;
}

.video-area {
    background-image: url("img/video-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding-bottom: 165px;
}

.video-area::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    opacity: 0.9;
}

.video-content {
    position: relative;
    text-align: center;
}

.video-content h3 {
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 0;
    color: #fff;
}

.video-content .video-btn {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    background-color: #e24852;
    border-radius: 50%;
    color: #fff;
    position: relative;
    z-index: 1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.video-content .video-btn i {
    font-size: 45px;
    position: relative;
    top: 12px;
    left: 2px;
}

.video-content .video-btn:hover {
    background-color: #fff;
    color: #e24852;
}

.quote-area {
    margin-top: -100px;
    position: relative;
    z-index: 1;
}

.quote-area .container .col-lg-6 {
    padding-left: 0;
    padding-right: 0;
}

.quote-form {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    padding: 50px 40px;
    position: relative;
}

.quote-form .content {
    margin-bottom: 35px;
}

.quote-form .content span {
    color: #e24852;
    font-weight: 500;
}

.quote-form .content h3 {
    font-size: 32px;
    margin-top: 14px;
    margin-bottom: 0;
}

.quote-form .form-group {
    position: relative;
    margin-bottom: 15px;
}

.quote-form .form-group .form-control {
    padding: 15px 20px;
    border: 1px dashed #f6f6f6;
    color: #5d5d5d;
    border-radius: 10px;
    background-color: #f6f6f6;
    font-size: 14px;
    font-weight: 400;
    height: 60px;
}

.quote-form .form-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.quote-form .form-group i {
    position: absolute;
    top: 50%;
    right: 20px;
    line-height: 1;
    font-size: 18px;
    color: #04b8e5;
}

.quote-form textarea {
    height: 130px !important;
}

.quote-form .nice-select {
    height: 60px;
    width: 100%;
    line-height: 48px;
    font-size: 15px;
    margin-bottom: 15px;
    padding-left: 20px;
    border: 1px dashed #f6f6f6;
    color: #5d5d5d;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding-top: 5px;
}

.quote-form .nice-select .list {
    background-color: #fff;
    -webkit-box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    border-radius: 0;
    margin-top: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.quote-form .nice-select .list .option {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    color: #02162e;
    padding-left: 20px;
    padding-right: 20px;
}

.quote-form .nice-select .list .option:hover {
    background-color: #e24852 !important;
    color: #fff;
}

.quote-form .nice-select .list .option.selected {
    background-color: transparent;
    font-weight: 600;
}

.quote-form .nice-select::after {
    height: 8px;
    width: 8px;
    border-color: #04b8e5;
    right: 23px;
}

.quote-form .quote-btn {
    margin-top: 15px;
}

.quote-form .quote-btn .default-btn {
    border: 1px solid #e24852;
    cursor: pointer;
}

.quote-image {
    background-image: url("img/quote.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.projects-item {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: relative;
}

.projects-item .image {
    position: relative;
}

.projects-item .image .icon {
    position: absolute;
    right: 12px;
    bottom: -10px;
    z-index: 9;
}

.projects-item .image .icon i {
    display: inline-block;
    height: 30px;
    width: 35px;
    line-height: 30px;
    background-color: #e24852;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 22px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.projects-item .content {
    background-color: #f6f6f6;
    padding: 30px;
    position: relative;
    z-index: 1;
    border-radius: 0 0 8px 8px;
}

.projects-item .content h3 {
    font-size: 25px;
    margin-bottom: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.projects-item .content::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 0%;
    background: #e24852;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 0 0 8px 8px;
}

.projects-item:hover .image .icon i {
    background-color: #02162e;
    color: #fff;
}

.projects-item:hover .content::before {
    height: 100%;
}

.projects-item:hover .content h3 a {
    color: #fff;
}

.projects-item:hover .content span {
    color: #fff;
}

.projects-slider.owl-theme .owl-nav {
    margin-top: 0;
}

.projects-slider.owl-theme .owl-nav [class*="owl-"] {
    position: absolute;
    left: -10px;
    top: 40%;
    -webkit-transform: translateY(-40%);
    transform: translateY(-40%);
    margin: 0;
    outline: 0;
    width: 45px;
    height: 45px;
    line-height: 52px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #fff;
    color: #e24852;
    border-radius: 50%;
    opacity: 1;
    visibility: hidden;
}

.projects-slider.owl-theme .owl-nav [class*="owl-"]:hover,
.projects-slider.owl-theme .owl-nav [class*="owl-"]:focus {
    background-color: #02162e;
}

.projects-slider.owl-theme .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: -10px;
}

.projects-slider.owl-theme .owl-nav [class*="owl-"] i {
    font-size: 25px;
    font-weight: 700;
}

.projects-slider.owl-theme:hover .owl-nav [class*="owl-"] {
    opacity: 1;
    visibility: visible;
    left: -20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.projects-slider.owl-theme:hover .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: -20px;
}

.projects-area .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
}

.testimonial-area {
    background-image: url("img/testimonial-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}

.testimonial-area::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #fff;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0.8;
    z-index: -1;
}

.testimonial-area.bg-ffffff {
    background-image: unset;
}

.testimonial-area.bg-ffffff::before {
    display: none;
}

.testimonial-slider .testimonial-item {
    text-align: center;
    background-color: #e24852;
    padding: 30px 50px;
    border-radius: 20px;
    margin-top: 25px;
}

.testimonial-slider .testimonial-item .info {
    margin-top: -55px;
    margin-bottom: 20px;
}

.testimonial-slider .testimonial-item .info img {
    display: inline-block;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    margin-bottom: 16px;
}

.testimonial-slider .testimonial-item .info h3 {
    font-size: 25px;
    margin-bottom: 8px;
    color: #fff;
}

.testimonial-slider .testimonial-item .info span {
    color: #fff;
    font-weight: 500;
}

.testimonial-slider .testimonial-item .text p {
    margin-bottom: 0;
    font-style: italic;
    color: #fff;
    max-width: 850px;
    margin: auto;
}

.testimonial-slider .testimonial-item .icon {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.testimonial-slider .testimonial-item .icon i {
    font-size: 60px;
    color: #f9cbce;
}

.testimonial-slider.owl-theme .owl-dots .owl-dot span {
    width: 20px;
    height: 20px;
    background-color: transparent;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 50%;
    border: 1px solid #e24852;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
}

.testimonial-slider.owl-theme .owl-dots .owl-dot span::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #e24852;
    border-radius: 50%;
    margin: 4px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.testimonial-slider.owl-theme .owl-dots .owl-dot:hover span::before,
.testimonial-slider.owl-theme .owl-dots .owl-dot.active span::before {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.testimonial-slider.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 35px;
    line-height: 0;
}

.testimonial-slider.owl-theme .owl-nav {
    margin-top: 30px;
}

.testimonial-slider.owl-theme .owl-nav [class*="owl-"] {
    position: absolute;
    left: 0;
    top: 40%;
    -webkit-transform: translateY(-40%);
    transform: translateY(-40%);
    margin: 0;
    outline: 0;
    width: 45px;
    height: 45px;
    line-height: 52px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #fff;
    color: #e24852;
    border-radius: 50%;
    opacity: 1;
    visibility: hidden;
}

.testimonial-slider.owl-theme .owl-nav [class*="owl-"]:hover,
.testimonial-slider.owl-theme .owl-nav [class*="owl-"]:focus {
    background-color: #02162e;
}

.testimonial-slider.owl-theme .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: 0;
}

.testimonial-slider.owl-theme .owl-nav [class*="owl-"] i {
    font-size: 25px;
    font-weight: 700;
}

.testimonial-slider.owl-theme:hover .owl-nav [class*="owl-"] {
    opacity: 1;
    visibility: visible;
    left: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.testimonial-slider.owl-theme:hover .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: 5px;
}

.team-area {
    background-color: #0f171f;
}

.team-area.bg-ffffff {
    background-color: #fff;
}

.team-area.bg-ffffff .section-title h2 {
    color: #02162e;
}

.team-area.bg-ffffff .section-title p {
    color: #02162e;
}

.team-area .section-title h2 {
    color: #fff;
}

.team-area .section-title p {
    color: #fff;
}

.single-team-box {
    margin-bottom: 30px;
    text-align: center;
    margin-top: 20px;
    margin-right: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-team-box .image {
    position: relative;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 1;
}

.single-team-box .image::before {
    content: "";
    position: absolute;
    right: -15px;
    top: -15px;
    border-radius: 5px;
    z-index: -1;
    width: 100%;
    height: 100%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border: 5px solid #e24852;
}

.single-team-box .image img {
    border-radius: 5px 5px 0 0;
}

.single-team-box .image .social {
    padding-left: 0;
    list-style-type: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
}

.single-team-box .image .social li {
    display: inline-block;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    opacity: 0;
    visibility: hidden;
    margin-right: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-team-box .image .social li:last-child {
    margin-right: 0;
}

.single-team-box .image .social li a {
    display: block;
    width: 35px;
    height: 35px;
    background-color: #e24852;
    text-align: center;
    position: relative;
    font-size: 18px;
    color: #fff;
    border-radius: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-team-box .image .social li a i {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
}

.single-team-box .image .social li a:hover {
    color: #e24852;
    background-color: #fff;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
}

.single-team-box .content {
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 0 0 5px 5px;
}

.single-team-box .content h3 {
    margin-bottom: 0;
    font-size: 25px;
}

.single-team-box .content span {
    display: block;
    color: #e24852;
    margin-top: 10px;
}

.single-team-box:hover {
    margin-top: 0;
    margin-right: 0;
}

.single-team-box:hover .image::before {
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
}

.single-team-box:hover .image .social li {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.blog-area {
    background-color: #fafafa;
}

.blog-item {
    margin-bottom: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.blog-item .image {
    position: relative;
}

.blog-item .image .date {
    position: absolute;
    top: 0;
    left: 0;
}

.blog-item .image .date span {
    display: inline-block;
    padding: 10px 25px;
    background-color: #fff;
    color: #e24852;
    font-weight: 400;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.blog-item .content {
    padding: 25px 20px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
}

.blog-item .content h3 {
    font-size: 25px;
    line-height: 1.5;
    margin-bottom: 14px;
}

.blog-item .content h3 a {
    color: #02162e;
}

.blog-item .content .blog-btn {
    font-weight: 600;
    color: #e24852;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.blog-item:hover .image .date span {
    background-color: #e24852;
    color: #fff;
}

.blog-item:hover .content h3 a {
    color: #e24852;
}

.blog-item:hover .content .blog-btn {
    letter-spacing: 1px;
}

.newsletter-inner {
    background-color: #e24852;
    padding: 55px 70px;
    border-radius: 30px;
    margin-bottom: -30px;
    z-index: 1;
    position: relative;
}

.newsletter-content {
    position: relative;
    z-index: 1;
}

.newsletter-content .icon {
    position: absolute;
    top: -16px;
    z-index: -1;
    left: 0;
}

.newsletter-content .icon i {
    font-size: 120px;
    color: #fdced233;
}

.newsletter-content h2 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 10px;
}

.newsletter-content p {
    color: #fff;
    margin-bottom: 0;
}

.newsletter-form {
    position: relative;
}

.newsletter-form .input-newsletter {
    display: block;
    width: 100%;
    background-color: #fff;
    border: 1px solid #fff;
    height: 60px;
    padding-left: 25px;
    border-radius: 15px;
    outline: 0;
    color: #02162e;
}

.newsletter-form .input-newsletter::-webkit-input-placeholder {
    color: #6b6b84;
}

.newsletter-form .input-newsletter:-ms-input-placeholder {
    color: #6b6b84;
}

.newsletter-form .input-newsletter::-ms-input-placeholder {
    color: #6b6b84;
}

.newsletter-form .input-newsletter::placeholder {
    color: #6b6b84;
}

.newsletter-form button {
    position: absolute;
    right: 4px;
    top: 4px;
    background-color: #e24852;
    color: #fff;
    border: none;
    height: 52px;
    padding: 0 30px;
    border-radius: 15px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    line-height: 50px;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}

.newsletter-form button:hover {
    background-color: #02162e;
    color: #fff;
}

.newsletter-form #validator-newsletter {
    color: #fff;
    position: relative;
    top: 8px;
    font-size: 16px;
    font-weight: 500;
}

.features-area {
    position: relative;
    margin-top: -30px;
    z-index: 1;
}

.features-item {
    padding: 30px;
    background-color: #fff;
    text-align: center;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: relative;
    z-index: 1;
}

.features-item .icon {
    margin-bottom: 25px;
    position: relative;
}

.features-item .icon i {
    font-size: 60px;
    color: #e24852;
}

.features-item .icon .shape {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
}

.features-item h3 {
    font-size: 25px;
    margin-bottom: 14px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.features-item h3 a {
    color: #02162e;
}

.features-item:hover h3 a {
    color: #e24852;
}

.gallery-area {
    overflow: hidden;
}

.gallery-area .container-fluid {
    padding: 0;
}

.gallery-item {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: relative;
}

.gallery-item .content {
    background-color: #f6f6f6;
    position: absolute;
    width: 90%;
    bottom: 10px;
    border-radius: 0;
    padding: 15px;
}

.gallery-item .content h3 {
    font-size: 22px;
    margin-bottom: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.gallery-item .content::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 0%;
    background: #e24852;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.gallery-item .content .icon {
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9;
}

.gallery-item .content .icon i {
    display: inline-block;
    height: 30px;
    width: 35px;
    line-height: 30px;
    background-color: #e24852;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 22px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.gallery-item:hover .content::before {
    height: 100%;
}

.gallery-item:hover .content h3 a {
    color: #e24852;
}

.gallery-item:hover .content .icon i {
    background-color: #02162e;
    color: #fff;
}

.gallery-slider.owl-theme .owl-nav {
    margin-top: 30px;
    line-height: 0;
}

.gallery-slider.owl-theme .owl-nav [class*="owl-"] {
    position: absolute;
    left: 10px;
    top: 40%;
    -webkit-transform: translateY(-40%);
    transform: translateY(-40%);
    margin: 0;
    outline: 0;
    width: 45px;
    height: 45px;
    line-height: 52px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #fff;
    color: #e24852;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
}

.gallery-slider.owl-theme .owl-nav [class*="owl-"]:hover,
.gallery-slider.owl-theme .owl-nav [class*="owl-"]:focus {
    background-color: #02162e;
}

.gallery-slider.owl-theme .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: 10px;
}

.gallery-slider.owl-theme .owl-nav [class*="owl-"] i {
    font-size: 25px;
    font-weight: 700;
}

.gallery-slider.owl-theme:hover .owl-nav [class*="owl-"] {
    opacity: 1;
    visibility: visible;
}

.gallery-slider.owl-theme .owl-dots .owl-dot span {
    width: 20px;
    height: 20px;
    background-color: transparent;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 50%;
    border: 1px solid #e24852;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
}

.gallery-slider.owl-theme .owl-dots .owl-dot span::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #e24852;
    border-radius: 50%;
    margin: 4px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.gallery-slider.owl-theme .owl-dots .owl-dot:hover span::before,
.gallery-slider.owl-theme .owl-dots .owl-dot.active span::before {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.reasons-area {
    background-image: url("img/reasons-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}

.reasons-area::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0.8;
    z-index: -1;
}

.reasons-image {
    position: relative;
    z-index: 1;
    background-image: url("img/reasons.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.reasons-image .video-btn {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    background-color: #e24852;
    border-radius: 50%;
    color: #fff;
    z-index: 1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.reasons-image .video-btn i {
    font-size: 48px;
    position: relative;
    top: 10px;
    left: 12px;
}

.reasons-image .video-btn:hover {
    background-color: #fff;
    color: #e24852;
}

.reasons-item {
    padding-top: 30px;
    padding-bottom: 100px;
}

.reasons-item .text span {
    color: #e24852;
}

.reasons-item .text h3 {
    font-size: 38px;
    margin-top: 15px;
    margin-bottom: 0;
    color: #fff;
}

.reasons-item .reasons-content {
    position: relative;
    margin-top: 40px;
    padding-left: 65px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.reasons-item .reasons-content .icon {
    position: absolute;
    left: 0;
    top: 0;
}

.reasons-item .reasons-content .icon i {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    background-color: #e24852;
    color: #fff;
    font-size: 25px;
    text-align: center;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.reasons-item .reasons-content h4 {
    font-size: 25px;
    margin-bottom: 16px;
    color: #fff;
}

.reasons-item .reasons-content p {
    color: #fff;
}

.reasons-item .reasons-content:hover .icon i {
    background-color: #fff;
    color: #e24852;
}

.faq-content {
    margin-bottom: 32px;
}

.faq-content span {
    color: #e24852;
}

.faq-content h3 {
    font-size: 38px;
    margin-top: 14px;
    margin-bottom: 15px;
}

.faq-accordion .accordion {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item {
    display: block;
    background: #fef7fb;
    margin-bottom: 10px;
}

.faq-accordion .accordion .accordion-item:last-child {
    margin-bottom: 0;
}

.faq-accordion .accordion .accordion-title {
    padding: 25px 40px 20px 20px;
    color: #02162e;
    position: relative;
    display: block;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
}

.faq-accordion .accordion .accordion-title i {
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #02162e;
    font-size: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.faq-accordion .accordion .accordion-title.active i::before {
    content: "\f068";
    color: #e24852;
}

.faq-accordion .accordion .accordion-content {
    display: none;
    position: relative;
    padding: 18px 20px;
    border-top: 1px solid #e24852;
    color: var(--optionalColor);
}

.faq-accordion .accordion .accordion-content.show {
    display: block;
}

.faq-image {
    background-image: url("img/faq.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.faq-contact-form #contactForm .form-group {
    margin-bottom: 15px;
}

.faq-contact-form #contactForm .form-group .form-control {
    height: 60px;
    padding: 0 0 0 15px;
    line-height: initial;
    color: #02162e;
    background-color: transparent;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 16px;
    font-weight: 400;
}

.faq-contact-form #contactForm .form-group .form-control:focus {
    border-color: #e24852;
}

.faq-contact-form #contactForm .form-group textarea.form-control {
    height: auto;
    padding: 15px 0 0 15px;
}

.faq-contact-form #contactForm .form-group .help-block.with-errors ul {
    color: red;
    margin-bottom: 0;
    margin-top: 10px;
}

.faq-contact-form #contactForm #msgSubmit {
    margin: 0;
    font-size: 1.3rem;
}

.faq-contact-form #contactForm #msgSubmit.text-danger,
.faq-contact-form #contactForm #msgSubmit.text-success {
    margin-top: 15px;
}

.faq-contact-form #contactForm .send-btn .default-btn {
    border: 1px solid #e24852;
}

.solution-area {
    background-color: #f0f0f0;
}

.solution-image {
    position: relative;
    z-index: 1;
    background-image: url("img/reasons.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.solution-image .video-btn {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    background-color: #e24852;
    border-radius: 50%;
    color: #fff;
    z-index: 1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.solution-image .video-btn i {
    font-size: 48px;
    position: relative;
    top: 10px;
    left: 12px;
}

.solution-image .video-btn:hover {
    background-color: #fff;
    color: #e24852;
}

.solution-item {
    padding-top: 30px;
    padding-bottom: 100px;
}

.solution-item .text span {
    color: #e24852;
}

.solution-item .text h3 {
    font-size: 38px;
    margin-top: 15px;
    margin-bottom: 0;
}

.solution-item .solution-content {
    position: relative;
    margin-top: 40px;
    padding-left: 85px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.solution-item .solution-content .icon {
    position: absolute;
    left: 0;
    top: 0;
}

.solution-item .solution-content .icon i {
    display: inline-block;
    height: 70px;
    width: 70px;
    line-height: 70px;
    background-color: #fff;
    color: #e24852;
    font-size: 45px;
    text-align: center;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.solution-item .solution-content h4 {
    font-size: 25px;
    margin-bottom: 16px;
}

.solution-item .solution-content:hover .icon i {
    background-color: #e24852;
    color: #fff;
}

.single-pricing {
    margin-bottom: 30px;
    text-align: center;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding-bottom: 35px;
}

.single-pricing .pricing-image {
    background-color: #fceeef;
    padding: 10px 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-pricing .pricing-header {
    margin-top: 25px;
}

.single-pricing .pricing-header h3 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    color: #e24852;
    margin-bottom: 15px;
}

.single-pricing .price {
    font-size: 35px;
    font-weight: 700;
    color: #02162e;
    line-height: 1;
    margin-bottom: 25px;
}

.single-pricing .price span {
    font-weight: 500;
    color: #6b6b84;
    display: block;
    font-size: 16px;
    margin-top: 12px;
}

.single-pricing .pricing-features {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.single-pricing .pricing-features li {
    margin-bottom: 12px;
    position: relative;
    text-transform: capitalize;
    font-weight: 500;
}

.single-pricing .pricing-features li:last-child {
    margin-bottom: 0;
}

.single-pricing .pricing-features li i {
    margin-right: 5px;
    color: #e24852;
}

.single-pricing .pricing-btn {
    margin-top: 25px;
}

.single-pricing .pricing-btn .default-btn {
    border: 1px solid #e24852;
}

.single-pricing:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.single-pricing:hover .pricing-image {
    background-color: #fdced2;
}

.page-title-area {
    position: relative;
    z-index: 1;
    background-image: url("img/page-banner/bg-1.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
}

.page-title-area::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    opacity: 0.5;
}

.page-title-area.item-bg1 {
    background-image: url("img/page-banner/bg-2.jpg");
}

.page-title-area.item-bg2 {
    background-image: url("img/page-banner/bg-3.jpg");
}

.page-title-area.item-bg3 {
    background-image: url("img/page-banner/bg-4.jpg");
}

.page-title-area.item-bg4 {
    background-image: url("img/page-banner/bg-5.jpg");
}

.page-title-content {
    text-align: center;
}

.page-title-content h2 {
    margin-bottom: 0;
    color: #fff;
    margin-bottom: 30px;
    font-size: 35px;
}

.page-title-content ul {
    text-align: center;
    position: relative;
    right: 0;
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.page-title-content ul li {
    display: inline-block;
    margin-left: 15px;
    font-weight: 600;
    color: #e24852;
    position: relative;
}

.page-title-content ul li::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 6px;
    height: 12px;
    width: 1px;
    background-color: #e24852;
}

.page-title-content ul li:first-child {
    margin-left: 0;
}

.page-title-content ul li:first-child::before {
    display: none;
}

.page-title-content ul li a {
    display: block;
    color: #fff;
}

.appointment-form {
    border-radius: 5px;
    background-color: #fff;
    -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
    padding: 30px;
    text-align: center;
}

.appointment-form form .form-group {
    margin-bottom: 15px;
}

.appointment-form form .form-control {
    padding: 15px 20px;
    border: 1px solid #f1f1f1;
    color: #5d5d5d;
    border-radius: 10px;
    background-color: transparent;
    font-weight: 400;
    height: 60px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 5px;
}

.appointment-form form .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    border: 1px solid #e24852;
}

.appointment-form form textarea {
    height: 150px !important;
}

.appointment-form form button {
    display: inline-block;
    margin-top: 10px;
    color: #fff;
    background-color: #e24852;
    padding: 14px 25px;
    border: 1px solid #e24852;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
}

.appointment-form form button:hover {
    border-color: #e24852;
    color: #e24852;
    background-color: transparent;
    -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
}

.login-form {
    background: #fff;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 30px;
    border-radius: 5px;
    max-width: 1000px;
    margin: auto;
}

.login-form .sign-in-title {
    margin-bottom: 30px;
    text-align: center;
}

.login-form .sign-in-title h3 {
    font-size: 30px;
    margin-bottom: 8px;
}

.login-form .sign-in-title p {
    margin-bottom: 30px;
}

.login-form form .form-group {
    margin-bottom: 15px;
}

.login-form form .form-control {
    height: 60px;
    border: 1px solid #eee;
    border-radius: 3px;
    color: #02162e;
    padding-left: 20px;
    background: 0 0;
}

.login-form form .form-control:focus {
    border-color: #e24852;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.login-form form .form-control::-webkit-input-placeholder {
    color: #6b6b84;
}

.login-form form .form-control:-ms-input-placeholder {
    color: #6b6b84;
}

.login-form form .form-control::-ms-input-placeholder {
    color: #6b6b84;
}

.login-form form .form-control::placeholder {
    color: #6b6b84;
}

.login-form form .form-check {
    margin-top: 18px;
}

.login-form form .form-check .form-check-label {
    color: #6b6b84;
    position: relative;
    z-index: 1;
    font-family: mulish, sans-serif;
    font-weight: 500;
}

.login-form form .form-check-input {
    margin-top: 3px;
}

.login-form form .send-btn {
    text-align: center;
    margin-top: 20px;
}

.login-form form .send-btn .default-btn {
    cursor: pointer;
    border: 1px solid #e24852;
}

.login-form form span {
    display: block;
    color: #777;
}

.login-form form span a {
    color: #e24852;
}

.login-form .forgot-password {
    margin-bottom: 0;
    margin-top: -25px;
    text-align: right;
}

.login-form .forgot-password a {
    color: #777;
    font-family: mulish, sans-serif;
    font-weight: 500;
}

.login-form .forgot-password a:hover {
    color: #e24852;
}

.register-form {
    background: #fff;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;
    text-align: center;
}

.register-form .register-in-title {
    margin-bottom: 30px;
}

.register-form .register-in-title h3 {
    font-size: 30px;
    margin-bottom: 0;
}

.register-form .register-in-title p {
    margin-bottom: 30px;
}

.register-form form .form-group {
    margin-bottom: 15px;
}

.register-form form .form-control {
    height: 60px;
    border: 1px solid #eee;
    border-radius: 3px;
    color: #02162e;
    padding-left: 20px;
    background: 0 0;
}

.register-form form .form-control:focus {
    border-color: #e24852;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.register-form form .form-control::-webkit-input-placeholder {
    color: #6b6b84;
}

.register-form form .form-control:-ms-input-placeholder {
    color: #6b6b84;
}

.register-form form .form-control::-ms-input-placeholder {
    color: #6b6b84;
}

.register-form form .form-control::placeholder {
    color: #6b6b84;
}

.register-form form .send-btn {
    margin-top: 20px;
}

.register-form form .send-btn .default-btn {
    cursor: pointer;
    border: 1px solid #e24852;
}

.register-form form span {
    display: block;
    color: #6b6b84;
}

.register-form form span a {
    color: #e24852;
}

.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;
}

.error-content h3 {
    font-size: 38px;
    margin-top: 45px;
    margin-bottom: 15px;
}

.error-content p {
    max-width: 520px;
    margin: 0 auto 20px;
}

.error-content .default-btn {
    border: 1px solid #e24852;
}

.privacy-content h3 {
    font-size: 25px;
    margin: 0 0 16px;
}

.privacy-content ol,
.privacy-content ul {
    margin-top: 20px;
}

.privacy-content ol li,
.privacy-content ul li {
    margin-bottom: 10px;
    color: #6b6b84;
    line-height: 1.8;
    font-weight: 400;
}

.contact-info-box {
    text-align: center;
    border-radius: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: #fff;
    padding: 30px;
    margin-bottom: 30px;
}

.contact-info-box .icon {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    background: #f5f5f5;
    border-radius: 50%;
    font-size: 35px;
    color: #e24852;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 12px;
    position: relative;
}

.contact-info-box .icon i {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
}

.contact-info-box h3 {
    margin-bottom: 12px;
    font-size: 23px;
    font-weight: 700;
}

.contact-info-box p {
    margin-bottom: 2px;
}

.contact-info-box p a {
    display: inline-block;
    color: #6b6b84;
}

.contact-info-box p a:hover {
    color: #e24852;
}

.contact-info-box p:last-child {
    margin-bottom: 0;
}

.contact-info-box:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.contact-info-box:hover .icon {
    background-color: #e24852;
    color: #fff;
    border-color: #e24852;
}

.contact-form .form-group {
    margin-bottom: 15px;
}

.contact-form .form-group .form-control {
    display: block;
    width: 100%;
    height: 50px;
    outline: 0;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0 12px;
}

.contact-form .form-group .form-control:focus {
    outline: 0;
    background-color: #fff;
    border-color: #e24852;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.contact-form .form-group textarea.form-control {
    height: 200px;
    padding: 8px 12px;
    line-height: 1.5rem;
}

.contact-form .form-group .help-block.with-errors ul {
    color: red;
    margin-bottom: 0;
    margin-top: 10px;
}

.contact-form .form-group label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}

.contact-form #msgSubmit {
    margin: 0;
    font-size: 1.3rem;
}

.contact-form #msgSubmit.text-danger,
.contact-form #msgSubmit.text-success {
    margin-top: 15px;
}

.contact-form .default-btn {
    border: 1px solid #e24852;
}

.contact-form .form-check {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 500;
    color: #6b6b84;
}

.contact-form .form-check a {
    color: #e24852;
}

.services-details-desc h3 {
    margin-top: 30px;
    margin-bottom: 12px;
    font-size: 25px;
}

.services-details-desc p {
    margin-bottom: 0;
}

.services-details-desc .features-list {
    padding-left: 0;
    list-style-type: none;
    margin-top: 25px;
    margin-bottom: 0;
}

.services-details-desc .features-list li {
    position: relative;
    color: #02162e;
    margin-bottom: 15px;
    padding-left: 25px;
    line-height: 1.8;
    font-weight: 500;
    font-size: 18px;
}

.services-details-desc .features-list li i {
    color: #e24852;
    position: absolute;
    left: 0;
    top: 8px;
}

.services-details-desc .features-list li:last-child {
    margin-bottom: 0;
}

.services-details-desc .text-result h3 {
    margin-top: 30px;
    margin-bottom: 12px;
    font-size: 25px;
}

.services-details-desc .text-result p {
    margin-bottom: 0;
}

.services-details-desc .services-details-faq {
    margin-top: 30px;
}

.services-details-desc .services-details-faq .accordion {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.services-details-desc .services-details-faq .accordion .accordion-item {
    display: block;
    -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    background: #fff;
    margin-bottom: 10px;
}

.services-details-desc .services-details-faq .accordion .accordion-item:last-child {
    margin-bottom: 0;
}

.services-details-desc .services-details-faq .accordion .accordion-title {
    padding: 18px 20px 18px 55px;
    color: #02162e;
    position: relative;
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;
    display: block;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 500;
}

.services-details-desc .services-details-faq .accordion .accordion-title i {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    text-align: center;
    height: 100%;
    background: #e24852;
    color: #fff;
}

.services-details-desc .services-details-faq .accordion .accordion-title i::before {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 25px;
}

.services-details-desc .services-details-faq .accordion .accordion-title.active {
    border-bottom-color: #e24852;
}

.services-details-desc .services-details-faq .accordion .accordion-title.active i::before {
    content: "\f068";
}

.services-details-desc .services-details-faq .accordion .accordion-content {
    display: none;
    position: relative;
    padding: 15px;
    font-size: 15px;
}

.services-details-desc .services-details-faq .accordion .accordion-content.show {
    display: block;
}

.project-details-image {
    margin-bottom: 30px;
}

.projects-details-desc h3 {
    margin-bottom: 14px;
    font-size: 28px;
}

.projects-details-desc .features-text {
    margin-top: 20px;
    margin-bottom: 25px;
}

.projects-details-desc .features-text h4 {
    margin-bottom: 12px;
    font-size: 20px;
}

.projects-details-desc .features-text h4 i {
    font-size: 22px;
    margin-right: 4px;
    color: #e24852;
}

.projects-details-desc .text {
    margin-top: 25px;
}

.widget-area .widget {
    margin-top: 30px;
}

.widget-area .widget:first-child {
    margin-top: 0;
}

.widget-area .widget .widget-title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 25px;
    text-transform: capitalize;
    position: relative;
    font-size: 20px;
}

.widget-area .widget .widget-title::before {
    content: "";
    position: absolute;
    background: #e24852;
    bottom: -1px;
    left: 0;
    width: 50px;
    height: 1px;
}

.widget-area .widget_search {
    -webkit-box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    background-color: #fff;
    padding: 15px;
}

.widget-area .widget_search form {
    position: relative;
}

.widget-area .widget_search form label {
    display: block;
    margin-bottom: 0;
}

.widget-area .widget_search form .screen-reader-text {
    display: none;
}

.widget-area .widget_search form .search-field {
    background-color: transparent;
    height: 50px;
    padding: 6px 15px;
    border: 1px solid #eee;
    width: 100%;
    display: block;
    outline: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.widget-area .widget_search form .search-field:focus {
    border-color: #e24852;
}

.widget-area .widget_search form button {
    position: absolute;
    right: 0;
    outline: 0;
    bottom: 0;
    height: 50px;
    width: 50px;
    z-index: 1;
    border: none;
    color: #fff;
    background-color: #e24852;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    cursor: pointer;
}

.widget-area .widget_search form button:hover {
    background-color: #02162e;
    color: #fff;
}

.widget-area .widget_projects_info {
    background-color: #fff;
    -webkit-box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-top: 3px solid #e24852;
    padding: 40px 30px;
}

.widget-area .widget_projects_info ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.widget-area .widget_projects_info ul li {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    position: relative;
    color: #6b6b84;
    font-weight: 600;
    font-size: 16.5px;
    padding-left: 35px;
    padding-bottom: 20px;
}

.widget-area .widget_projects_info ul li span {
    color: #02162e;
    display: block;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
}

.widget-area .widget_projects_info ul li a {
    display: block;
    color: #6b6b84;
    margin-bottom: 8px;
}

.widget-area .widget_projects_info ul li a:last-child {
    margin-bottom: 0;
}

.widget-area .widget_projects_info ul li a:hover {
    color: #e24852;
}

.widget-area .widget_projects_info ul li .icon {
    position: absolute;
    left: 0;
    top: 0;
    color: #e24852;
    font-weight: 400;
    font-size: 25px;
}

.widget-area .widget_projects_info ul li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}

.widget-area .widget_contact {
    position: relative;
    z-index: 1;
    border-radius: 5px;
    height: 400px;
    text-align: center;
    margin-top: 30px;
    background-image: url("img/emergency-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.widget-area .widget_contact::before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    background-color: #000;
    opacity: 0.5;
}

.widget-area .widget_contact .text {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 35px;
}

.widget-area .widget_contact .text span {
    display: block;
    color: #fff;
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 700;
    font-family: mulish, sans-serif;
}

.widget-area .widget_contact .text a {
    display: block;
    color: #fff;
    font-weight: 400;
    font-size: 25px;
    font-family: mulish, sans-serif;
}

.widget-area .widget_services_list ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.widget-area .widget_services_list ul li {
    margin-bottom: 12px;
}

.widget-area .widget_services_list ul li a {
    display: block;
    color: #02162e;
    -webkit-box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    background-color: #fff;
    padding: 20px;
    position: relative;
    font-weight: 700;
}

.widget-area .widget_services_list ul li a i {
    display: inline-block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 20px;
    z-index: 1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: #02162e;
    font-size: 20px;
}

.widget-area .widget_services_list ul li a:hover {
    background-color: #e24852;
    color: #fff;
    -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(254, 35, 9, 0.2);
    box-shadow: 0 5px 28.5px 1.5px rgba(254, 35, 9, 0.2);
}

.widget-area .widget_services_list ul li a:hover i {
    right: 15px;
    color: #fff;
}

.widget-area .widget_services_list ul li a:hover i::after {
    background-color: rgba(255, 255, 255, 0.4);
}

.widget-area .widget_services_list ul li.active a {
    background-color: #e24852;
    color: #fff;
    -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(254, 35, 9, 0.2);
    box-shadow: 0 5px 28.5px 1.5px rgba(254, 35, 9, 0.2);
}

.widget-area .widget_services_list ul li.active a i {
    color: #fff;
}

.widget-area .widget_services_list ul li.active a i::after {
    background-color: rgba(255, 255, 255, 0.4);
}

.widget-area .widget_services_list ul li:last-child {
    margin-bottom: 0;
}

.widget-area .widget_download_list {
    -webkit-box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    background-color: #fff;
    padding: 20px;
}

.widget-area .widget_download_list ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.widget-area .widget_download_list ul li {
    margin-bottom: 10px;
}

.widget-area .widget_download_list ul li a {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    font-weight: 500;
    display: block;
    padding: 14px 15px;
}

.widget-area .widget_download_list ul li a:hover {
    background-color: #e24852;
    color: #fff;
}

.widget-area .widget_download_list ul li a i {
    margin-right: 5px;
    position: relative;
    top: 2px;
    font-size: 25px;
}

.widget-area .widget_download_list ul li:last-child {
    margin-bottom: 0;
}

.widget-area .widget_lebo_posts_thumb {
    position: relative;
    overflow: hidden;
}

.widget-area .widget_lebo_posts_thumb .item {
    overflow: hidden;
    margin-bottom: 15px;
}

.widget-area .widget_lebo_posts_thumb .item:last-child {
    margin-bottom: 0;
}

.widget-area .widget_lebo_posts_thumb .item .thumb {
    float: left;
    height: 80px;
    overflow: hidden;
    position: relative;
    width: 80px;
    margin-right: 15px;
}

.widget-area .widget_lebo_posts_thumb .item .thumb .fullimage {
    width: 80px;
    height: 80px;
    display: inline-block;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center center !important;
}

.widget-area .widget_lebo_posts_thumb .item .thumb .fullimage.bg1 {
    background-image: url("img/blog/blog1.jpg");
}

.widget-area .widget_lebo_posts_thumb .item .thumb .fullimage.bg2 {
    background-image: url("img/blog/blog2.jpg");
}

.widget-area .widget_lebo_posts_thumb .item .thumb .fullimage.bg3 {
    background-image: url("img/blog/blog3.jpg");
}

.widget-area .widget_lebo_posts_thumb .item .info {
    overflow: hidden;
    margin-top: 5px;
}

.widget-area .widget_lebo_posts_thumb .item .info time {
    display: block;
    color: #6b6b84;
    text-transform: capitalize;
    margin-top: -2px;
    margin-bottom: 5px;
    font-size: 14px;
}

.widget-area .widget_lebo_posts_thumb .item .info .title {
    margin-bottom: 0;
    line-height: 1.4;
    font-size: 18px;
}

.widget-area .widget_lebo_posts_thumb .item .info .title a {
    display: inline-block;
}

.widget-area .widget_categories ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.widget-area .widget_categories ul li {
    position: relative;
    margin-bottom: 12px;
    color: #6b6b84;
    padding-left: 14px;
    font-size: 15.5px;
    font-weight: 500;
}

.widget-area .widget_categories ul li:last-child {
    margin-bottom: 0;
}

.widget-area .widget_categories ul li::before {
    background: #e24852;
    height: 8px;
    width: 8px;
    content: "";
    left: 0;
    top: 8px;
    position: absolute;
    border-radius: 30px;
}

.widget-area .widget_categories ul li a {
    color: #02162e;
    display: block;
}

.widget-area .widget_categories ul li a:hover {
    color: #e24852;
}

.widget-area .widget_archive ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.widget-area .widget_archive ul li {
    position: relative;
    margin-bottom: 12px;
    color: #6b6b84;
    padding-left: 14px;
    font-size: 15.5px;
    font-weight: 500;
}

.widget-area .widget_archive ul li:last-child {
    margin-bottom: 0;
}

.widget-area .widget_archive ul li::before {
    background: #e24852;
    height: 8px;
    width: 8px;
    content: "";
    left: 0;
    top: 8px;
    position: absolute;
    border-radius: 30px;
}

.widget-area .widget_archive ul li a {
    color: #02162e;
    display: block;
}

.widget-area .widget_archive ul li a:hover {
    color: #e24852;
}

.widget-area .widget_tag_cloud .widget-title {
    margin-bottom: 12px;
}

.widget-area .tagcloud a {
    display: inline-block;
    color: #02162e;
    font-weight: 500;
    font-size: 14.5px !important;
    padding: 6px 13px;
    border: 1px dashed #eee;
    margin-top: 8px;
    margin-right: 4px;
}

.widget-area .tagcloud a:hover,
.widget-area .tagcloud a:focus {
    color: #fff;
    background-color: #e24852;
}

.blog-details-desc .article-content {
    margin-top: 30px;
}

.blog-details-desc .article-content .entry-meta {
    margin-bottom: -8px;
}

.blog-details-desc .article-content .entry-meta ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.blog-details-desc .article-content .entry-meta ul li {
    position: relative;
    display: inline-block;
    color: #02162e;
    margin-right: 20px;
}

.blog-details-desc .article-content .entry-meta ul li span {
    display: inline-block;
    color: #02162e;
    font-weight: 500;
}

.blog-details-desc .article-content .entry-meta ul li a {
    display: inline-block;
    color: #6b6b84;
}

.blog-details-desc .article-content .entry-meta ul li a:hover {
    color: #e24852;
}

.blog-details-desc .article-content .entry-meta ul li i {
    color: #e24852;
    margin-right: 2px;
}

.blog-details-desc .article-content .entry-meta ul li::before {
    content: "";
    position: absolute;
    top: 12px;
    right: -15px;
    width: 6px;
    height: 1px;
    background: #e24852;
}

.blog-details-desc .article-content .entry-meta ul li:last-child {
    margin-right: 0;
}

.blog-details-desc .article-content .entry-meta ul li:last-child::before {
    display: none;
}

.blog-details-desc .article-content h3 {
    margin-bottom: 15px;
    margin-top: 25px;
    font-size: 25px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 {
    padding-left: 0;
    list-style-type: none;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
    -ms-flex: 0 0 33.3333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    padding-right: 10px;
    padding-left: 10px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
    margin-bottom: 0;
}

.blog-details-desc .article-content .features-list {
    padding-left: 0;
    list-style-type: none;
    margin-top: 25px;
    margin-bottom: 30px;
}

.blog-details-desc .article-content .features-list li {
    margin-bottom: 16px;
    position: relative;
    padding-left: 34px;
    color: #6b6b84;
}

.blog-details-desc .article-content .features-list li i {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 40px;
    background-color: #faf5f5;
    color: #e24852;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: inline-block;
    font-size: 11px;
    position: absolute;
    left: 0;
    top: -2px;
}

.blog-details-desc .article-content .features-list li:hover i {
    background-color: #e24852;
    color: #fff;
}

.blog-details-desc .article-content .features-list li:last-child {
    margin-bottom: 0;
}

.blog-details-desc .article-footer {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 30px;
}

.blog-details-desc .article-footer .article-tags {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    color: #6b6b84;
}

.blog-details-desc .article-footer .article-tags span {
    display: inline-block;
    color: #02162e;
    font-size: 20px;
    margin-right: 5px;
    position: relative;
    top: 2px;
}

.blog-details-desc .article-footer .article-tags a {
    display: inline-block;
    margin-right: 2px;
    font-weight: 500;
    color: #6b6b84;
}

.blog-details-desc .article-footer .article-tags a:hover {
    color: #e24852;
}

.blog-details-desc .article-footer .article-share {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
}

.blog-details-desc .article-footer .article-share .social {
    padding-left: 0;
    list-style-type: none;
    text-align: right;
    margin-bottom: 0;
}

.blog-details-desc .article-footer .article-share .social li {
    display: inline-block;
}

.blog-details-desc .article-footer .article-share .social li span {
    display: inline-block;
    margin-right: 2px;
    font-weight: 500;
    color: #6b6b84;
}

.blog-details-desc .article-footer .article-share .social li a {
    display: block;
    color: #e24852;
    width: 30px;
    height: 30px;
    line-height: 34px;
    border-radius: 50%;
    background-color: #eeeef0;
    text-align: center;
}

.blog-details-desc .article-footer .article-share .social li a:hover {
    color: #fff;
    background-color: #e24852;
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
}

blockquote,
.blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 30px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin-bottom: 20px;
    margin-top: 20px;
}

blockquote p,
.blockquote p {
    color: #02162e;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 20px !important;
}

blockquote cite,
.blockquote cite {
    display: none;
}

blockquote::after,
.blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #e24852;
    margin-top: 20px;
    margin-bottom: 20px;
}

.post-navigation {
    margin-top: 30px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-top: 20px;
    padding-bottom: 20px;
}

.post-navigation .navigation-links {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.post-navigation .navigation-links .nav-previous {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
}

.post-navigation .navigation-links .nav-previous a i {
    margin-right: 2px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 22px;
    position: relative;
    top: 2px;
}

.post-navigation .navigation-links .nav-previous a:hover i {
    margin-right: 0;
}

.post-navigation .navigation-links .nav-next {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    text-align: right;
}

.post-navigation .navigation-links .nav-next a i {
    margin-left: 2px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 22px;
    position: relative;
    top: 2px;
}

.post-navigation .navigation-links .nav-next a:hover i {
    margin-left: 0;
}

.post-navigation .navigation-links div a {
    display: inline-block;
    font-weight: 600;
}

.comments-area {
    padding: 25px;
    margin-top: 30px;
    -webkit-box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.08);
    box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.08);
    background-color: #fff;
}

.comments-area .comments-title {
    position: relative;
    margin-bottom: 30px;
    line-height: initial;
    font-size: 25px;
}

.comments-area ol,
.comments-area ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.comments-area .children {
    margin-left: 30px;
}

.comments-area .comment-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.comments-area .comment-body {
    border-bottom: 1px solid #eee;
    padding-left: 115px;
    color: #02162e;
    font-size: 14px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.comments-area .comment-body .reply {
    margin-top: 15px;
}

.comments-area .comment-body .reply a {
    border: 1px solid #ded9d9;
    color: #02162e;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 30px;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
    font-size: 14px;
    font-weight: 400;
}

.comments-area .comment-body .reply a:hover {
    color: #fff;
    background-color: #e24852;
    border-color: #e24852;
}

.comments-area .comment-author {
    font-size: 18px;
    margin-bottom: 0.1em;
    position: relative;
    z-index: 2;
}

.comments-area .comment-author .avatar {
    height: 100px;
    left: -115px;
    position: absolute;
    width: 100px;
    border-radius: 50px;
}

.comments-area .comment-author .fn {
    font-size: 20px;
}

.comments-area .comment-author .says {
    display: none;
}

.comments-area .comment-metadata {
    margin-bottom: 0.8em;
    color: #6b6b84;
    letter-spacing: 0.01em;
    font-size: 14px;
    font-weight: 400;
}

.comments-area .comment-metadata a {
    color: #6b6b84;
}

.comments-area .comment-metadata a:hover {
    color: #e24852;
}

.comment-respond-reply {
    padding: 25px;
    margin-top: 30px;
    -webkit-box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.08);
    box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.08);
    background-color: #fff;
}

.comment-respond-reply .comment-reply-title {
    margin-bottom: 0;
    position: relative;
    font-size: 25px;
}

.comment-respond-reply .comment-reply-title #cancel-comment-reply-link {
    display: inline-block;
}

.comment-respond-reply .comment-form {
    overflow: hidden;
}

.comment-respond-reply .comment-notes {
    margin-bottom: 0;
    margin-top: 10px;
}

.comment-respond-reply .comment-form-comment {
    margin-top: 15px;
    float: left;
    width: 100%;
}

.comment-respond-reply label {
    display: block;
    font-weight: 400;
    color: #02162e;
    margin-bottom: 5px;
}

.comment-respond-reply input[type="date"],
.comment-respond-reply input[type="time"],
.comment-respond-reply input[type="datetime-local"],
.comment-respond-reply input[type="week"],
.comment-respond-reply input[type="month"],
.comment-respond-reply input[type="text"],
.comment-respond-reply input[type="email"],
.comment-respond-reply input[type="url"],
.comment-respond-reply input[type="password"],
.comment-respond-reply input[type="search"],
.comment-respond-reply input[type="tel"],
.comment-respond-reply input[type="number"],
.comment-respond-reply textarea {
    display: block;
    width: 100%;
    background-color: #fff;
    border: 1px solid #eee;
    padding: 0.625em 0.7375em;
    outline: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.comment-respond-reply input[type="date"]:focus,
.comment-respond-reply input[type="time"]:focus,
.comment-respond-reply input[type="datetime-local"]:focus,
.comment-respond-reply input[type="week"]:focus,
.comment-respond-reply input[type="month"]:focus,
.comment-respond-reply input[type="text"]:focus,
.comment-respond-reply input[type="email"]:focus,
.comment-respond-reply input[type="url"]:focus,
.comment-respond-reply input[type="password"]:focus,
.comment-respond-reply input[type="search"]:focus,
.comment-respond-reply input[type="tel"]:focus,
.comment-respond-reply input[type="number"]:focus,
.comment-respond-reply textarea:focus {
    border-color: #e24852;
}

.comment-respond-reply .comment-form-author {
    float: left;
    width: 50%;
    padding-right: 10px;
    margin-bottom: 20px;
}

.comment-respond-reply .comment-form-email {
    float: left;
    width: 50%;
    padding-left: 12px;
    margin-bottom: 20px;
}

.comment-respond-reply .comment-form-url {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.comment-respond-reply .comment-form-cookies-consent {
    width: 100%;
    float: left;
    position: relative;
    padding-left: 20px;
    margin-bottom: 20px;
}

.comment-respond-reply .comment-form-cookies-consent input {
    position: absolute;
    left: 0;
    top: 4px;
}

.comment-respond-reply .comment-form-cookies-consent label {
    display: inline-block;
    margin: 0;
    color: #6b6b84;
    font-weight: 400;
    position: relative;
    top: -2px;
}

.comment-respond-reply .form-submit {
    float: left;
    width: 100%;
}

.comment-respond-reply .form-submit input {
    background: #e24852;
    border: none;
    color: #fff;
    padding: 10px 30px;
    display: inline-block;
    cursor: pointer;
    outline: 0;
    border-radius: 5px;
    text-transform: capitalize;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-weight: 500;
    font-size: 16px;
}

.comment-respond-reply .form-submit input:hover,
.comment-respond-reply .form-submit input:focus {
    color: #fff;
    background-color: #02162e;
}

.single-products-box {
    position: relative;
    margin-bottom: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-products-box .products-image {
    text-align: center;
    position: relative;
    overflow: hidden;
    display:flex;
    margin:auto;
}

.single-products-box .products-image a {
    position: relative;
    display: block;
}

.single-products-box .products-image a img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-products-box .products-content {
    margin-top: 20px;
    position: relative;
}

.single-products-box .products-content h3 {
    margin-bottom: 0;
    font-size: 22px;
}

.single-products-box .products-content h3 a {
    display: inline-block;
    color: #02162e;
}

.single-products-box .products-content h3 a:hover {
    color: #e24852;
}

.single-products-box .products-content .price {
    margin-top: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-weight: 600;
}

.single-products-box .products-content .price .old-price {
    text-decoration: line-through;
    color: #999;
    font-weight: 500;
}

.single-products-box .products-content .star-rating {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #f49f0b;
}

.single-products-box .products-content .star-rating i {
    display: inline-block;
    margin-left: -2px;
}

.single-products-box .products-content .add-to-cart {
    position: absolute;
    left: 0;
    bottom: 0;
    text-transform: capitalize;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: inline-block;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    font-size: 14px;
    font-weight: 600;
}

.single-products-box .products-content .add-to-cart:hover {
    color: #e24852;
}

.single-products-box:hover .products-content .price {
    opacity: 0;
    visibility: hidden;
}

.single-products-box:hover .products-content .add-to-cart {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

.single-products-box:hover .products-image a img {
    -webkit-transform: scale(1.07);
    transform: scale(1.07);
}

.cart-table table {
    margin-bottom: 0;
}

.cart-table table thead tr th {
    border-bottom-width: 0;
    vertical-align: middle;
    padding: 30px 0;
    text-transform: uppercase;
    border: none;
    white-space: nowrap;
    font-weight: 700;
}

.cart-table table tbody tr td {
    vertical-align: middle;
    color: 0.6s;
    white-space: nowrap;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    border-color: #eaedff;
    border-left: none;
    border-right: none;
}

.cart-table table tbody tr td.product-thumbnail a {
    display: block;
}

.cart-table table tbody tr td.product-thumbnail a img {
    width: 80px;
}

.cart-table table tbody tr td.product-name a {
    color: #666;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
}

.cart-table table tbody tr td.product-name a:hover {
    color: #e24852;
}

.cart-table table tbody tr td.product-subtotal .remove {
    color: #e24852;
    float: right;
    position: relative;
    top: -1px;
}

.cart-table table tbody tr td.product-quantity .input-counter {
    max-width: 130px;
    min-width: 130px;
    text-align: center;
    display: inline-block;
    position: relative;
}

.cart-table table tbody tr td.product-quantity .input-counter span {
    position: absolute;
    top: 0;
    background-color: transparent;
    cursor: pointer;
    color: #d0d0d0;
    width: 40px;
    height: 100%;
    line-height: 48px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
}

.cart-table table tbody tr td.product-quantity .input-counter span.minus-btn {
    left: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span.plus-btn {
    right: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span:hover {
    color: #e24852;
}

.cart-table table tbody tr td.product-quantity .input-counter input {
    height: 45px;
    color: #e24852;
    outline: 0;
    display: block;
    border: none;
    background-color: #f8f8f8;
    text-align: center;
    width: 100%;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 17px;
    font-weight: 600;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
    color: #e24852;
}

.cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
    color: #e24852;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
    color: #e24852;
}

.cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
    color: #e24852;
}

.cart-table table tbody tr td.product-subtotal {
    overflow: hidden;
}

.cart-buttons {
    margin-top: 30px;
}

.cart-buttons .col-lg-5 {
    text-align: right;
}

.cart-buttons .shopping-coupon-code {
    position: relative;
    max-width: 530px;
}

.cart-buttons .shopping-coupon-code .form-control {
    height: 53px;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #e24852;
}

.cart-buttons .shopping-coupon-code button {
    position: absolute;
    right: 0;
    top: 0;
    height: 53px;
    background: #e24852;
    color: #fff;
    border: none;
    padding: 0 25px;
    line-height: 54px;
    font-weight: 600;
    outline: 0;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    text-transform: uppercase;
}

.cart-buttons .shopping-coupon-code button:hover {
    background-color: #fdced2;
    color: #fff;
}

.cart-buttons .default-btn {
    padding: 14px 30px;
    background-color: #e24852;
    color: #fff;
    border: 1px solid #e24852;
    text-decoration: none;
    display: inline-block;
}

.cart-buttons .default-btn:hover {
    background-color: #fff;
    color: #e24852;
    -webkit-transition: 0.6s;
    transition: 0.6s;
}

.cart-totals {
    background: #fff;
    padding: 40px;
    max-width: 620px;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.cart-totals h3 {
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: 700;
}

.cart-totals ul {
    padding-left: 0;
    margin: 0 0 25px;
    list-style-type: none;
}

.cart-totals ul li {
    border: 1px solid #eaedff;
    padding: 10px 15px;
    color: #02162e;
    overflow: hidden;
    font-weight: 600;
}

.cart-totals ul li:first-child {
    border-bottom: none;
}

.cart-totals ul li:last-child {
    border-top: none;
}

.cart-totals ul li b {
    font-weight: 500;
}

.cart-totals ul li span {
    float: right;
    color: #6b6b84;
    font-weight: 500;
}

.cart-totals .default-btn {
    border: 1px solid #e24852;
}

.user-actions {
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: #fff;
    padding: 18px 20px 15px;
    margin-bottom: 65px;
    border-top: 3px solid #02162e;
    position: relative;
}

.user-actions::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: -35px;
    width: 100%;
    height: 1px;
    background: #eee;
}

.user-actions i {
    color: #02162e;
    margin-right: 2px;
    font-size: 25px;
    color: #e24852;
    position: relative;
    top: 2px;
}

.user-actions span {
    display: inline-block;
    font-weight: 400;
    color: #02162e;
    font-size: 16px;
}

.user-actions span a {
    display: inline-block;
    color: #02162e;
    text-decoration: none;
}

.user-actions span a:hover,
.user-actions span a:focus {
    color: #e24852;
}

.billing-details .title {
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    font-size: 24px;
    font-weight: 700;
}

.billing-details .title::before {
    content: "";
    position: absolute;
    background: #e24852;
    bottom: -1px;
    left: 0;
    width: 50px;
    height: 1px;
}

.billing-details .form-group {
    margin-bottom: 25px;
}

.billing-details .form-group label {
    display: block;
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: 400;
}

.billing-details .form-group label .required {
    color: #02162e;
}

.billing-details .form-group .nice-select {
    float: unset;
    line-height: 53px;
    color: #666;
    font-weight: 500;
    padding-top: 0;
    padding-bottom: 0;
}

.billing-details .form-group .nice-select .list {
    background-color: #fff;
    -webkit-box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
    border-radius: 0;
    margin-top: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.billing-details .form-group .nice-select .list .option {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    padding-left: 20px;
    padding-right: 20px;
}

.billing-details .form-group .nice-select .list .option:hover {
    background-color: #e24852 !important;
    color: #fff;
}

.billing-details .form-group .nice-select .list .option.selected {
    background-color: transparent;
    font-weight: 600;
}

.billing-details .form-group .nice-select:after {
    right: 20px;
}

.billing-details .form-group .form-control {
    height: 50px;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.billing-details .form-group .form-control:focus {
    border-color: #e24852;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.billing-details .form-group textarea.form-control {
    height: auto;
    padding-top: 15px;
}

.billing-details .form-check {
    margin-bottom: 20px;
}

.billing-details .form-check .form-check-label {
    color: #02162e;
}

.billing-details .form-check label {
    position: relative;
    left: -3px;
    top: 1px;
    font-weight: 500;
}

.billing-details .col-lg-12:last-child .form-group {
    margin-bottom: 0;
}

.order-details .title {
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    font-size: 24px;
    font-weight: 700;
}

.order-details .title::before {
    content: "";
    position: absolute;
    background: #e24852;
    bottom: -1px;
    left: 0;
    width: 50px;
    height: 1px;
}

.order-details .order-table table {
    margin-bottom: 0;
}

.order-details .order-table table thead tr th {
    border-bottom-width: 0;
    vertical-align: middle;
    border-color: #eaedff;
    text-transform: uppercase;
    padding-left: 20px;
    padding-top: 15px;
    padding-right: 20px;
    padding-bottom: 10px;
    font-weight: 600;
}

.order-details .order-table table tbody tr td {
    vertical-align: middle;
    color: #666;
    border-color: #eaedff;
    font-size: 15.5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 13px;
}

.order-details .order-table table tbody tr td.product-name a {
    color: #666;
    display: inline-block;
    text-decoration: none;
    font-weight: 500;
}

.order-details .order-table table tbody tr td.product-name a:hover {
    color: #e24852;
}

.order-details .order-table table tbody tr td.order-subtotal span,
.order-details .order-table table tbody tr td.order-shipping span,
.order-details .order-table table tbody tr td.total-price span {
    color: #666;
    font-weight: 500;
}

.order-details .order-table table tbody tr td.shipping-price,
.order-details .order-table table tbody tr td.order-subtotal-price,
.order-details .order-table table tbody tr td.product-subtotal {
    font-weight: 600;
}

.order-details .payment-box {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    margin-top: 30px;
    padding: 30px;
}

.order-details .payment-box .payment-method p [type="radio"]:checked,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked) {
    display: none;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label {
    padding-left: 27px;
    cursor: pointer;
    display: block;
    color: #02162e;
    position: relative;
    margin-bottom: 8px;
    font-weight: 600;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::before,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 50%;
    background: #fff;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::after,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::after {
    content: "";
    width: 12px;
    height: 12px;
    background: #e24852;
    position: absolute;
    top: 6px;
    left: 3px;
    border-radius: 50%;
    -webkit-transition: 0.6s;
    transition: 0.6s;
}

.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::after {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::after {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.order-details .payment-box .default-btn.order-btn {
    display: block;
    margin-top: 25px;
    -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
    box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
    border: 1px solid #e24852;
}

.product-details-image {
    background-image: url("img/product-details.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
}

.product-details-desc {
    padding: 15px;
    padding-left: 0;
    padding-right: 0;
}

.product-details-desc h3 {
    margin-bottom: 18px;
    font-size: 24px;
    font-weight: 700;
}

.product-details-desc .price {
    margin-bottom: 15px;
    color: #000;
    font-size: 15px;
    font-weight: 600;
}

.product-details-desc .price .old-price {
    text-decoration: line-through;
    color: #828893;
}

.product-details-desc p {
    margin-bottom: 0;
}

.product-details-desc .product-review {
    margin-bottom: 15px;
}

.product-details-desc .product-review .rating {
    display: inline-block;
    padding-right: 5px;
    font-size: 14px;
}

.product-details-desc .product-review .rating i {
    color: #ffba0a;
}

.product-details-desc .product-review .rating-count {
    display: inline-block;
    color: #000;
    border-bottom: 1px solid #000;
    line-height: initial;
}

.product-details-desc .product-review .rating-count:hover {
    color: #e24852;
    border-color: #e24852;
    text-decoration: none;
}

.product-details-desc .product-add-to-cart {
    margin-top: 20px;
}

.product-details-desc .product-add-to-cart .input-counter {
    max-width: 130px;
    min-width: 130px;
    margin-right: 10px;
    text-align: center;
    display: inline-block;
    position: relative;
}

.product-details-desc .product-add-to-cart .input-counter span {
    position: absolute;
    top: 0;
    background-color: transparent;
    cursor: pointer;
    color: #d0d0d0;
    width: 50px;
    height: 100%;
    line-height: 55px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.product-details-desc .product-add-to-cart .input-counter span.minus-btn {
    left: 0;
}

.product-details-desc .product-add-to-cart .input-counter span.plus-btn {
    right: 0;
}

.product-details-desc .product-add-to-cart .input-counter span:hover {
    color: #e24852;
}

.product-details-desc .product-add-to-cart .input-counter input {
    height: 50px;
    color: #000;
    outline: 0;
    display: block;
    border: none;
    background-color: #f8f8f8;
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
}

.product-details-desc .product-add-to-cart .input-counter input::-webkit-input-placeholder {
    color: #000;
}

.product-details-desc .product-add-to-cart .input-counter input:-ms-input-placeholder {
    color: #000;
}

.product-details-desc .product-add-to-cart .input-counter input::-ms-input-placeholder {
    color: #000;
}

.product-details-desc .product-add-to-cart .input-counter input::placeholder {
    color: #000;
}

.product-details-desc .product-add-to-cart .default-btn {
    position: relative;
    padding: 12px 30px;
    background-color: #e24852;
    color: #fff;
    border: 1px solid #e24852;
    cursor: pointer;
}

.product-details-desc .product-add-to-cart .default-btn i {
    margin-right: 2px;
}

.product-details-desc .product-add-to-cart .default-btn:hover {
    background-color: #fff;
    color: #e24852;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.product-details-desc .buy-checkbox-btn {
    margin-top: 20px;
}



.product-details-desc .buy-checkbox-btn .cbx {
    margin: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.product-details-desc .buy-checkbox-btn .cbx span {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.product-details-desc .buy-checkbox-btn .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    -webkit-transform: scale(1);
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #ebebeb;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.product-details-desc .buy-checkbox-btn .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.product-details-desc .buy-checkbox-btn .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #000;
    display: block;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    -webkit-transition: 0.6s;
    transition: 0.6s;
}

.product-details-desc .buy-checkbox-btn .cbx span:last-child {
    position: relative;
    top: 1px;
    padding-left: 4px;
    color: #666;
}

.product-details-desc .buy-checkbox-btn .cbx:hover span:first-child {
    border-color: #e24852;
}

.product-details-desc .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child {
    background: #e24852;
    border-color: #e24852;
    -webkit-animation: wave 0.4s ease;
    animation: wave 0.4s ease;
}

.product-details-desc .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child svg {
    stroke-dashoffset: 0;
}

.product-details-desc .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child:before {
    -webkit-transform: scale(3.5);
    transform: scale(3.5);
    opacity: 0;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.product-details-desc .buy-checkbox-btn .item:not(:first-child) {
    margin-top: 15px;
}

.product-details-desc .buy-checkbox-btn .btn-light {
    background-color: #f2f2f2;
    border: none;
    padding: 13px 25px 10px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    font-weight: 600;
    display: block;
    width: 100%;
}

.product-details-desc .buy-checkbox-btn .btn-light:hover {
    background-color: #e24852;
    color: #fff;
}

.product-details-desc .products-share {
    margin-top: 30px;
}

.product-details-desc .products-share .social {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}

.product-details-desc .products-share .social li {
    display: inline-block;
}

.product-details-desc .products-share .social li span {
    display: inline-block;
    margin-right: 3px;
    font-weight: 700;
    position: relative;
    top: -2px;
}

.product-details-desc .products-share .social li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    background-color: #e24852;
    color: #fff;
    border: 1px solid #e24852;
    text-align: center;
    font-size: 18px;
    margin-left: 2px;
}

.product-details-desc .products-share .social li a:hover,
.product-details-desc .products-share .social li a:focus {
    color: #e24852;
    background-color: transparent;
}

.product-details-desc .products-share .social li a.facebook {
    background-color: #3b5998;
    border-color: #3b5998;
    color: #fff;
}

.product-details-desc .products-share .social li a.facebook:hover,
.product-details-desc .products-share .social li a.facebook:focus {
    color: #3b5998;
    background-color: transparent;
}

.product-details-desc .products-share .social li a.twitter {
    background-color: #1da1f2;
    border-color: #1da1f2;
    color: #fff;
}

.product-details-desc .products-share .social li a.twitter:hover,
.product-details-desc .products-share .social li a.twitter:focus {
    color: #1da1f2;
    background-color: transparent;
}

.product-details-desc .products-share .social li a.linkedin {
    background-color: #007bb5;
    border-color: #007bb5;
    color: #fff;
}

.product-details-desc .products-share .social li a.linkedin:hover,
.product-details-desc .products-share .social li a.linkedin:focus {
    color: #007bb5;
    background-color: transparent;
}

.product-details-desc .products-share .social li a.instagram {
    background-color: #c13584;
    border-color: #c13584;
    color: #fff;
}

.product-details-desc .products-share .social li a.instagram:hover,
.product-details-desc .products-share .social li a.instagram:focus {
    color: #c13584;
    background-color: transparent;
}

.products-details-tab {
    margin-top: 50px;
}

.products-details-tab .tabs {
    list-style-type: none;
    margin-bottom: -1px;
    padding-left: 0;
}

.products-details-tab .tabs li {
    display: inline-block;
    line-height: initial;
    margin-right: 5px;
}

.products-details-tab .tabs li a {
    display: inline-block;
    position: relative;
    text-transform: capitalize;
    color: #02162e;
    border: 1px dashed #eee;
    text-decoration: none;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 30px;
    padding-left: 30px;
    font-weight: 600;
    font-size: 18px;
}

.products-details-tab .tabs li a:hover,
.products-details-tab .tabs li a:focus {
    color: #fff;
    background-color: #e24852;
    border-color: #e24852;
}

.products-details-tab .tabs li.current a {
    color: #fff;
    background-color: #e24852;
    border-color: #e24852;
}

.products-details-tab .tabs li:last-child {
    margin-right: 0;
}

.products-details-tab .tab_content {
    border: 1px dashed #eee;
    padding: 30px;
}

.products-details-tab .tab_content .tabs_item {
    display: none;
}

.products-details-tab .tab_content .tabs_item:first-child {
    display: block;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content p {
    margin-bottom: 20px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content p:last-child {
    margin-bottom: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content h3 {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 700;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title {
    position: relative;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating {
    display: inline-block;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating .fas.fa-star {
    color: #ffba0a;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating i {
    color: #ffba0a;
    font-size: 14px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title p {
    margin-bottom: 0;
    display: inline-block;
    padding-left: 5px;
    line-height: initial;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .default-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px 20px;
    background-color: #e24852;
    color: #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 15px;
    border: 1px solid #e24852;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .default-btn:hover {
    background-color: #fff;
    color: #e24852;
    text-decoration: none;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments {
    margin-top: 35px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item {
    margin-top: 30px;
    position: relative;
    padding-right: 200px;
    border-top: 1px dashed #eee;
    padding-top: 30px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating .fas.fa-star {
    color: #ffba0a;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating i {
    font-size: 14px;
    color: #ffba0a;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item h3 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span {
    margin-bottom: 10px;
    font-size: 13px;
    display: block;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span strong {
    font-weight: 600;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item p {
    margin-bottom: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link {
    position: absolute;
    right: 0;
    color: #666;
    top: 40px;
    text-decoration: underline;
    font-weight: 500;
    font-size: 15px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link:hover {
    color: #e24852;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form {
    margin-top: 30px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form h3 {
    margin-bottom: 20px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form {
    max-width: 100%;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group {
    margin-bottom: 15px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group .form-control {
    height: 55px;
    background-color: #eee;
    border: 1px solid #eee;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: #02162e;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group .form-control:focus {
    background-color: transparent;
    border: 1px solid #e24852;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group textarea.form-control {
    padding-top: 15px;
    height: auto;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form .default-btn {
    border: 1px solid #e24852;
    cursor: pointer;
}

.footer-area {
    background-color: #19212a;
    padding-top: 125px;
}

.single-footer-widget {
    margin-bottom: 30px;
}

.single-footer-widget .logo {
    margin-bottom: 25px;
}

.single-footer-widget .logo h2 a {
    color: #fff;
}

.single-footer-widget p {
    color: #fff;
    margin-bottom: 0;
}

.single-footer-widget .social {
    padding-left: 0;
    list-style-type: none;
    margin-top: 25px;
    margin-bottom: 0;
}

.single-footer-widget .social li {
    display: inline-block;
    margin-right: 10px;
}

.single-footer-widget .social li:last-child {
    margin-right: 0;
}

.single-footer-widget .social li a {
    display: block;
    width: 35px;
    height: 35px;

    line-height: 35px;
    border-radius: 50%;
    color: #fff;
    border: 1px solid #e24852;
    text-align: center;
    font-size: 18px;
}

.single-footer-widget .social li a:hover,
.single-footer-widget .social li a:focus {
    color: #e24852;
    background-color: transparent;
}

.single-footer-widget .social li a.facebook {
    background-color: #3b5998;
    border-color: #3b5998;
    color: #fff;
}

.single-footer-widget .social li a.facebook:hover,
.single-footer-widget .social li a.facebook:focus {
    color: #3b5998;
    background-color: transparent;
    text-underline-offset: 1px;
    text-decoration-line: underline;
}

.single-footer-widget .social li a.twitter {
    background-color: #1da1f2;
    border-color: #1da1f2;
    color: #fff;
}

.single-footer-widget .social li a.twitter:hover,
.single-footer-widget .social li a.twitter:focus {
    color: #1da1f2;
    background-color: transparent;
    text-underline-offset: 1px;
    text-decoration-line: underline;

}

.single-footer-widget .social li a.linkedin {
    background-color: #007bb5;
    border-color: #007bb5;
    color: #fff;
}

.single-footer-widget .social li a.linkedin:hover,
.single-footer-widget .social li a.linkedin:focus {
    color: #007bb5;
    background-color: transparent;
    text-underline-offset: 1px;
    text-decoration-line: underline;
}


.single-footer-widget h3 {
    font-size: 22px;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 25px;
    color: #e24852;
}

.single-footer-widget h3::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 2px;
    width: 50px;
    background-color: #e24852;
}

.single-footer-widget .quick-links {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.single-footer-widget .quick-links li {
    margin-bottom: 15px;
    color: #fff;
    font-size: 15px;
}

.single-footer-widget .quick-links li a {
    display: inline-block;
    color: #fff;
    font-weight: 500;
    position: relative;
}

.single-footer-widget .quick-links li a:hover {
    color: #e24852;
}

.single-footer-widget .quick-links li a:hover::before {
    width: 100%;
}

.single-footer-widget .quick-links li a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #e24852;
}

.single-footer-widget .quick-links li i {
    color: #e24852;
}

.single-footer-widget .quick-links li:last-child {
    margin-bottom: 0;
}

.single-footer-widget .footer-contact-info {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.single-footer-widget .footer-contact-info li {
    margin-bottom: 20px;
    color: #fff;
    position: relative;
    padding-left: 35px;
}

.single-footer-widget .footer-contact-info li i {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 25px;
    color: #e24852;
}

.single-footer-widget .footer-contact-info li span {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
    color: #fff;
}

.single-footer-widget .footer-contact-info li a {
    display: inline-block;
    color: #fff;
    font-weight: 400;
    position: relative;
    font-size: 15px;
}

.single-footer-widget .footer-contact-info li a:hover {
    color: #e24852;
}

.single-footer-widget .footer-contact-info li a:hover::before {
    width: 100%;
}

.single-footer-widget .footer-contact-info li a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #e24852;
}

.single-footer-widget .footer-contact-info li:last-child {
    margin-bottom: 0;
}

.copyright-area {
    background-color:  #19212a;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;

}

.copyright-area p {
    color: #fff;
    font-size: small;
}

.copyright-area p a {
    color: #fff;
    display: inline-block;
    font-weight: 300;
}

.copyright-area p a:hover {
    color: #e24852;
}

.go-top {
    position: fixed;
    cursor: pointer;
    bottom: -100px;
    right: 20px;
    color: #fff;
    background-color: #02162e;
    z-index: 4;
    width: 45px;
    text-align: center;
    height: 45px;
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    font-size: 22px;
    -webkit-transition: 0.9s;
    transition: 0.9s;
    overflow: hidden;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.go-top i {
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
}

.go-top.active {
    opacity: 1;
    visibility: visible;
    bottom: 20px;
}

.go-top:hover {
    background-color: #e24852;
    color: #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.perso {
    color: white;
}
#devis1{
    min-width: 350px !important;
}
/* Partie pour les formulaires de contact et devis */
.text-black {
    color: #000; }

.content {
    padding: 7rem 0; }

.heading {
    font-size: 2.5rem;
    font-weight: 900; }

.form-control {
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    background: none; }
.form-control:active, .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #000; }

.col-form-label {
    color: #000;
    font-size: 13px; }

.btn, .form-control, .custom-select {
    height: 45px;
    border-radius: 0; }

.custom-select {
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0; }
.custom-select:active, .custom-select:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #000; }

.contact-wrap {
    -webkit-box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #efefef; }
.contact-wrap .col-form-label {
    font-size: 14px;
    color: #b3b3b3;
    margin: 0 0 10px 0;
    display: inline-block;
    padding: 0; }
.contact-wrap .form, .contact-wrap .contact-info {
    padding: 40px; }
.contact-wrap .contact-info {
    color: rgba(255, 255, 255, 0.5); }
.contact-wrap .contact-info ul li {
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.5); }
.contact-wrap .contact-info ul li .wrap-icon {
    font-size: 20px;
    color: #fff;
    margin-top: 5px; }
.contact-wrap .form {
    background: #fff; }
.contact-wrap .form h3 {
    color: #000;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 30px; }
.contact-wrap .contact-info {
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
.contact-wrap .contact-info a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
@media (max-width: 1199.98px) {
    .contact-wrap .contact-info {
        height: 400px !important; } }
.contact-wrap .contact-info h3 {
    color: #fff;
    font-size: 20px;
    margin-bottom: 30px; }
.envoi_btn{
    cursor: pointer;
    border: 1px solid #e24852;
    border-radius : 3px;
    margin-top: 20px;
}
.envoi_btn:hover{
    border-color: darkgreen;
    text-underline-color: #0b5ed7;
    background-color: #1da1f2;
    color: #FFFFFF;
}
textarea.form-control{
    min-height: calc(8.5rem + .75rem + 2px) !important;
}
#myBtn {
    background-color: #19212a;
    border-radius: 50%;
    bottom: 20px;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 18px;
    height: 45px;
    outline: none;
    padding: 15px;
    position: fixed;
    right: 41px;
    width: 45px;
    z-index: 99
}

#myBtn i {
    font-size: 30px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

#myBtn:hover {
    background-color: #6b8d73
}

.choice-none{
    background-image: none !important;
}
.gridBoutonDeco{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}
@media screen and (max-width: 798px) {
    .gridBoutonDeco{
        display: flex;
        flex-direction: column;
    }

}
