@media only screen and (max-width: 767px) {
  .default-btn {
    padding: 10px 20px;
    font-size: 15px
  }
  .ptb-100 {
    padding-top: 50px;
    padding-bottom: 50px
  }
  .pt-100 {
    padding-top: 50px
  }
  .pb-100 {
    padding-bottom: 50px
  }
  .pb-70 {
    padding-bottom: 20px
  }
  .section-title {
    margin-bottom: 30px
  }
  .section-title h2 {
    font-size: 25px
  }
  .section-title p {
    font-size: 15px
  }
  .top-header {
    padding-bottom: 20px
  }
  .top-left {
    text-align: center;
    margin-bottom: 15px
  }
  .top-social {
    text-align: center
  }
  .middle-header-area {
    text-align: center;
    padding-bottom: 25px
  }
  .middle-info {
    padding-left: 0;
    display: block;
    margin-right: 0;
    margin-bottom: 20px
  }
  .middle-info::before {
    display: none
  }
  .middle-info .icon {
    position: relative;
    margin-bottom: 12px
  }
  .middle-info .icon i {
    font-size: 25px
  }
  .middle-btn {
    text-align: center
  }
  .middle-logo {
    margin-bottom: 25px
  }
  .middle-logo h3 {
    line-height: 1
  }
  .middle-logo h3 a {
    font-size: 25px
  }
  .mean-container a.meanmenu-reveal {
    padding: 4px 0 0
  }
  .mean-container a.meanmenu-reveal span {
    display: block;
    background: #000;
    height: 4px;
    margin-top: -5px;
    border-radius: 3px;
    position: relative;
    top: 8px
  }
  .mean-container .mean-bar {
    background-color: unset;
    border-bottom: none
  }
  .mean-container .mean-nav {
    margin-top: 45px
  }
  .others-option-for-responsive .dot-menu {
    top: -32px
  }
  .others-option-for-responsive .container .container {
    right: 20px;
    max-width: 285px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px
  }
  .sidebar-modal .sidebar-modal-inner {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px
  }
  .sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a i {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px
  }
  .main-slider-item {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 100px
  }
  .main-slider-content {
    max-width: 100%;
    margin-top: 0
  }
  .main-slider-content strong {
    padding: 5px 20px;
    font-size: 16px
  }
  .main-slider-content h1 {
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 14px
  }
  .main-slider-content p {
    font-size: 15px
  }
  .main-slider-content .slider-btn {
    margin-top: 25px
  }
  .main-slider-content .slider-btn .default-btn-two {
    padding: 10px 20px;
    margin-left: 7px;
    font-size: 15px
  }
  .home-slides.owl-theme .owl-nav [class*=owl-] {
    top: 100%;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 1;
    visibility: visible
  }
  .home-slides.owl-theme:hover .owl-nav [class*=owl-] {
    left: 5px
  }
  .home-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next {
    right: 5px
  }
  .about-content h3 {
    font-size: 25px
  }
  .about-content .about-list li {
    font-size: 15px
  }
  .about-content .signature {
    padding: 0;
    margin-top: 30px;
    display: block
  }
  .about-content .signature::before {
    display: none
  }
  .about-content .signature img {
    position: relative;
    right: 0;
    top: 0;
    display: block;
    margin-top: 15px
  }
  .about-image {
    margin-bottom: 0;
    margin-top: 30px;
    text-align: center
  }
  .about-image::before {
    display: none
  }
  .about-image .about-years {
    left: 0
  }
  .services-item .content {
    padding: 25px
  }
  .services-item .content h3 {
    font-size: 22px
  }
  .services-slider.owl-theme .owl-nav [class*=owl-] {
    left: 0;
    visibility: visible
  }
  .services-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0
  }
  .services-slider.owl-theme:hover .owl-nav [class*=owl-] {
    left: 0
  }
  .services-slider.owl-theme:hover .owl-nav [class*=owl-].owl-next {
    right: 0
  }
  .choose-image {
    margin-top: 30px;
    margin-bottom: 30px
  }
  .choose-area {
    padding-bottom: 50px
  }
  .choose-item {
    padding-left: 0;
    text-align: center
  }
  .choose-item .icon {
    position: relative;
    margin-bottom: 14px
  }
  .choose-item h3 {
    font-size: 22px
  }
  .video-area {
    padding-bottom: 140px
  }
  .video-content h3 {
    font-size: 25px
  }
  .video-content .video-btn {
    width: 60px;
    height: 60px;
    line-height: 60px
  }
  .video-content .video-btn i {
    font-size: 40px;
    top: 10px;
    left: 2px
  }
  .quote-area {
    margin-top: -90px
  }
  .quote-form {
    padding: 30px 15px
  }
  .quote-form .content span {
    font-size: 15px
  }
  .quote-form .content h3 {
    font-size: 25px;
    margin-top: 12px
  }
  .quote-image {
    height: 500px
  }
  .projects-slider.owl-theme .owl-nav [class*=owl-] {
    left: 0;
    visibility: visible
  }
  .projects-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0
  }
  .projects-slider.owl-theme:hover .owl-nav [class*=owl-] {
    left: 0
  }
  .projects-slider.owl-theme:hover .owl-nav [class*=owl-].owl-next {
    right: 0
  }
  .projects-item .content {
    padding: 20px
  }
  .projects-item .content h3 {
    font-size: 22px
  }
  .testimonial-slider .testimonial-item {
    padding: 30px 20px
  }
  .testimonial-slider .testimonial-item .icon i {
    display: none
  }
  .testimonial-slider.owl-theme .owl-nav [class*=owl-] {
    display: none
  }
  .blog-item .content h3 {
    font-size: 20px
  }
  .newsletter-inner {
    padding: 30px 25px
  }
  .newsletter-content {
    margin-bottom: 20px
  }
  .newsletter-content h2 {
    font-size: 25px;
    line-height: 1;
    margin-top: -2px
  }
  .newsletter-form button {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 15px;
    background-color: #000
  }
  .footer-area {
    padding-top: 70px
  }
  .pl-5, .px-5 {
    padding-left: 0 !important
  }
  .main-banner-item {
    height: 100%;
    padding-top: 70px;
    padding-bottom: 60px
  }
  .main-banner-content h1 {
    font-size: 30px
  }
  .main-banner-content p {
    font-size: 15px
  }
  .features-area {
    margin-top: 0;
    padding-top: 20px
  }
  .features-item {
    margin-top: 30px;
    padding: 20px
  }
  .features-item h3 {
    font-size: 22px
  }
  .about-warp {
    position: relative;
    top: 0;
    margin-top: 30px
  }
  .reasons-image {
    height: 500px
  }
  .reasons-item {
    padding-bottom: 50px
  }
  .reasons-item .reasons-content h4 {
    font-size: 20px
  }
  .reasons-item .reasons-content p {
    font-size: 15px
  }
  .reasons-item .text h3 {
    font-size: 25px
  }
  .faq-content h3 {
    font-size: 25px
  }
  .faq-image {
    height: 500px;
    margin-top: 30px
  }
  .main-banner-content .banner-btn .default-btn-two {
    padding: 10px 15px;
    margin-left: 5px;
    font-size: 15px
  }
  .about-information {
    padding: 20px;
    margin-top: 30px
  }
  .about-inner h4 {
    font-size: 22px
  }
  .about-warp-image {
    padding-bottom: 0;
    padding-left: 0;
    margin-top: 30px
  }
  .about-warp-image img:nth-child(2) {
    left: 0;
    bottom: 0;
    max-width: 190px
  }
  .single-services-item {
    padding: 20px
  }
  .single-services-item h3 {
    font-size: 20px
  }
  .gallery-area .container-fluid {
    padding: 15px
  }
  .solution-image {
    height: 500px
  }
  .solution-item {
    padding-bottom: 50px
  }
  .solution-item .text h3 {
    font-size: 25px
  }
  .solution-item .solution-content h4 {
    font-size: 22px
  }
  .single-pricing .price {
    font-size: 25px
  }
  .banner-form {
    padding: 30px 10px;
    margin-top: 30px
  }
  .banner-form .content h3 {
    font-size: 25px
  }
  .page-title-area {
    height: 200px
  }
  .page-title-content h2 {
    font-size: 30px
  }
  .page-title-content ul {
    position: relative;
    left: 0;
    margin-top: 25px
  }
  .cart-table table thead tr th {
    padding: 0 65px 15px
  }
  .cart-buttons {
    text-align: center
  }
  .cart-buttons .default-btn {
    margin-bottom: 20px
  }
  .text-right {
    text-align: center !important
  }
  .cart-totals {
    padding: 20px;
    margin-top: 20px
  }
  .order-details .title {
    margin-top: 30px
  }
  .product-details-image {
    height: 500px;
    margin-bottom: 20px
  }
  .products-details-tab .tabs li a {
    font-size: 16px
  }
  .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .default-btn {
    display: none
  }
  .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item {
    padding-right: 0
  }
  .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link {
    position: relative;
    top: 0;
    margin-top: 15px
  }
  .login-form .forgot-password {
    margin-top: 5px;
    text-align: left
  }
  .services-details-desc h3 {
    font-size: 22px
  }
  .services-details-desc .features-list li {
    font-size: 16px
  }
  .services-details-desc .services-details-faq .accordion .accordion-title {
    font-size: 16px
  }
  .services-details-desc .text-result h3 {
    font-size: 22px
  }
  .widget-area .widget:first-child {
    margin-top: 30px
  }
  .projects-area .container-fluid {
    padding: 15px
  }
  .projects-details-desc h3 {
    font-size: 25px
  }
  .projects-details-desc .features-text h4 {
    font-size: 16px
  }
  .widget-area .widget_projects_info {
    padding: 20px
  }
  .widget-area .widget_projects_info ul li {
    font-size: 15px
  }
  .widget-area .widget_projects_info ul li span {
    font-size: 18px
  }
  .blog-details-desc .article-content h3 {
    font-size: 22px
  }
  .blog-details-desc .article-footer .article-share {
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    max-width: unset;
    margin-top: 20px
  }
  blockquote, .blockquote {
    padding: 20px !important
  }
  blockquote p, .blockquote p {
    font-size: 15px !important
  }
  .comments-area {
    padding: 20px
  }
  .comments-area .comment-author .avatar {
    height: 50px;
    width: 50px;
    left: -60px
  }
  .comments-area .children {
    margin-left: 0
  }
  .comments-area .comment-body {
    padding-left: 65px
  }
  .single-team-box .image::before {
    top: -20px;
    right: -20px
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .login-form .forgot-password {
    margin-top: -30px;
    text-align: right
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb-100 {
    padding-top: 70px;
    padding-bottom: 70px
  }
  .pt-100 {
    padding-top: 70px
  }
  .pb-100 {
    padding-bottom: 70px
  }
  .pb-70 {
    padding-bottom: 40px
  }
  .top-header {
    padding-bottom: 20px
  }
  .top-left {
    text-align: center;
    margin-bottom: 15px
  }
  .top-social {
    text-align: center
  }
  .middle-logo {
    text-align: center;
    margin-bottom: 30px
  }
  .middle-info {
    padding-left: 0;
    margin-right: 120px;
    text-align: center
  }
  .middle-info .icon {
    position: relative;
    margin-bottom: 15px
  }
  .middle-info::before {
    display: none
  }
  .middle-btn {
    text-align: center;
    margin-top: 30px
  }
  .mean-container a.meanmenu-reveal {
    padding: 4px 0 0
  }
  .mean-container a.meanmenu-reveal span {
    display: block;
    background: #000;
    height: 4px;
    margin-top: -5px;
    border-radius: 3px;
    position: relative;
    top: 8px
  }
  .mean-container .mean-bar {
    background-color: unset;
    border-bottom: none
  }
  .mean-container .mean-nav {
    margin-top: 45px
  }
  .others-option-for-responsive .dot-menu {
    top: -32px
  }
  .others-option-for-responsive .container .container {
    right: 20px;
    max-width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px
  }
  .sidebar-modal .sidebar-modal-inner {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px
  }
  .sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a i {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px
  }
  .main-slider-item {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 150px
  }
  .main-slider-content {
    max-width: 100%;
    margin-top: 0
  }
  .home-slides.owl-theme .owl-nav [class*=owl-] {
    top: 100%;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 1;
    visibility: visible
  }
  .home-slides.owl-theme:hover .owl-nav [class*=owl-] {
    left: 5px
  }
  .home-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next {
    right: 5px
  }
  .about-image {
    margin-bottom: 0;
    margin-top: 30px;
    text-align: center
  }
  .about-image::before {
    display: none
  }
  .about-image .about-years {
    left: 0
  }
  .services-slider.owl-theme .owl-nav [class*=owl-] {
    left: 0;
    visibility: visible
  }
  .services-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0
  }
  .services-slider.owl-theme:hover .owl-nav [class*=owl-] {
    left: 0
  }
  .services-slider.owl-theme:hover .owl-nav [class*=owl-].owl-next {
    right: 0
  }
  .choose-image {
    margin-top: 30px;
    margin-bottom: 30px
  }
  .choose-area {
    padding-bottom: 50px
  }
  .video-area {
    padding-bottom: 140px
  }
  .video-content .video-btn {
    width: 60px;
    height: 60px;
    line-height: 60px
  }
  .video-content .video-btn i {
    font-size: 40px;
    top: 10px;
    left: 2px
  }
  .quote-form {
    padding: 40px 35px
  }
  .quote-image {
    height: 500px
  }
  .projects-slider.owl-theme .owl-nav [class*=owl-] {
    left: 0;
    visibility: visible
  }
  .projects-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0
  }
  .projects-slider.owl-theme:hover .owl-nav [class*=owl-] {
    left: 0
  }
  .projects-slider.owl-theme:hover .owl-nav [class*=owl-].owl-next {
    right: 0
  }
  .testimonial-slider .testimonial-item {
    padding: 30px 20px
  }
  .testimonial-slider .testimonial-item .icon i {
    font-size: 30px
  }
  .testimonial-slider.owl-theme .owl-nav [class*=owl-] {
    display: none
  }
  .blog-item .content h3 {
    font-size: 20px
  }
  .newsletter-inner {
    padding: 30px 25px
  }
  .newsletter-content {
    margin-bottom: 20px
  }
  .newsletter-form button {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 15px;
    background-color: #000
  }
  .footer-area {
    padding-top: 100px
  }
  .pl-5, .px-5 {
    padding-left: 0 !important
  }
  .main-banner-item {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px
  }
  .features-area {
    margin-top: 0;
    padding-top: 40px
  }
  .features-item {
    margin-top: 30px;
    padding: 20px
  }
  .about-warp {
    position: relative;
    top: 0;
    margin-top: 30px;
    text-align: center
  }
  .reasons-image {
    height: 500px
  }
  .reasons-item {
    padding-bottom: 70px
  }
  .faq-image {
    height: 500px;
    margin-top: 30px
  }
  .about-information {
    padding: 20px;
    margin-top: 30px
  }
  .about-inner h4 {
    font-size: 22px
  }
  .about-warp-image {
    padding-bottom: 0;
    padding-left: 0;
    margin-top: 30px
  }
  .about-warp-image img:nth-child(2) {
    left: 0;
    bottom: 0
  }
  .single-services-item {
    padding: 20px
  }
  .gallery-area .container-fluid {
    padding: 15px
  }
  .solution-image {
    height: 500px
  }
  .solution-item {
    padding-bottom: 70px
  }
  .banner-form {
    margin-top: 30px
  }
  .order-details .title {
    margin-top: 30px
  }
  .product-details-image {
    height: 500px;
    margin-bottom: 20px
  }
  .widget-area .widget:first-child {
    margin-top: 30px
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .middle-info {
    padding-left: 30px;
    margin-right: 30px
  }
  .middle-info .icon i {
    font-size: 22px
  }
  .middle-info::before {
    right: -20px
  }
  .middle-logo h3 a {
    font-size: 30px
  }
  .middle-btn .default-btn {
    padding: 8px 14px;
    font-size: 14px
  }
  .middle-btn .default-btn i {
    font-size: 16px
  }
  .sidebar-modal .sidebar-modal-inner {
    width: 50%;
    padding-top: 50px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px
  }
  .about-image {
    margin-right: 30px
  }
  .home-slides.owl-theme .owl-nav [class*=owl-] {
    top: 55%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%)
  }
  .choose-item {
    padding-left: 50px
  }
  .choose-item .icon i {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 20px
  }
  .choose-item h3 {
    font-size: 22px
  }
  .choose-item p {
    font-size: 13px
  }
  .mean-container a.meanmenu-reveal {
    padding: 4px 0 0
  }
  .mean-container a.meanmenu-reveal span {
    display: block;
    background: #000;
    height: 4px;
    margin-top: -5px;
    border-radius: 3px;
    position: relative;
    top: 8px
  }
  .mean-container .mean-bar {
    background-color: unset;
    border-bottom: none;
    padding: 2px 0 0
  }
  .mean-container .mean-nav {
    margin-top: 45px
  }
  .others-option-for-responsive .dot-menu {
    top: -32px
  }
  .others-option-for-responsive .container .container {
    right: 20px;
    max-width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px
  }
  .features-item {
    padding: 20px
  }
  .features-item h3 {
    font-size: 20px
  }
  .single-services-item {
    padding: 20px
  }
  .single-services-item h3 {
    font-size: 22px
  }
  .blog-item .content h3 {
    font-size: 18px
  }
  .main-slider-content {
    margin-top: 0
  }
  .testimonial-slider .testimonial-item .icon i {
    font-size: 32px
  }
}
